import React, { useState, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { ArrowBigRightDash, ChevronDown } from "lucide-react";

const navItems = [
  {
    name: "Our Expertise",
    firstHead: "Domain Based Expertise",
    secondHead: "Stage Based Expertise",
    dropdownItems: [
      {
        name: "Enterprise Risk Management",
        link: "/industries/enterprise-risk-management",
      },
      {
        name: "Environmental, Social, and Governance",
        link: "/industries/environmental-social-governance",
      },
      {
        name: "Digital Personal Data Protection",
        link: "/industries/data-privacy-protection",
      },
      {
        name: "Governance, Risk, and Compliance",
        link: "/industries/governance-risk-compliance",
      },
      {
        name: "Work Health and Safety",
        link: "/industries/workplace-health-safety",
      },
      {
        name: "Security and Loss Prevention",
        link: "/industries/security-loss-prevention",
      },
    ],
    secondDropdownItems: [
      {
        title: "GRC Compliance Audit",
        link: "/stage/grc-compliance-audit",
      },
      {
        title: "Start-Up Audit",
        link: "/stage/start-up-audit",
      },
      {
        title: "Due-Diligence Audit",
        link: "/stage/due-diligence-audit",
      },
      {
        title: "IPO Audit",
        link: "/stage/ipo-audit",
      },
      {
        title: "M&A Audit",
        link: "/stage/m-and-a-audit",
      },
      {
        title: "Off-Boarding Audit",
        link: "/stage/off-boarding-audit",
      },
      {
        title: "FDI Audit",
        link: "/stage/fdi-india-audit",
      },
    ],
  },
  {
    name: "Platform",
    dropdownItems: [
      {
        name: "Platform",
        description: "Why EazyComply , Powerful Features",
        link: "/platform",
      },
      {
        name: "Pricing",
        description: "Flexible pricing options for your needs.",
        link: "/pricing",
      },
      {
        name: "FAQs",
        description: "Find answers to common questions.",
        link: "/faqs",
      },
    ],
  },
  {
    name: "Services",
    dropdownItems: [
      {
        name: "Legal update service",
        link: "/services/legal-updates",
      },
      {
        name: "Compliance Audit Service",
        link: "/services/compliance-audit",
      },
      {
        name: "SCOPE Service",
        link: "/services/scope",
      },
      {
        name: "Risk Assessment Service",
        link: "/services/risk-assessment",
      },
      {
        name: "Control Effectiveness Service",
        link: "/services/control-effectiveness",
      },
      {
        name: "Pricing",
        link: "/pricing",
      },
    ],
  },
  {
    name: "Company",

    dropdownItems: [
      {
        name: "About Us",
        link: "/about",
      },
      {
        name: "Leadership",
        link: "/leadership",
      },
      {
        name: "FAQs",
        link: "/faqs",
      },
      {
        name: "Blog",
        link: "/blog",
      },
      {
        name: "Contact Us",
        link: "/contact",
      },
      {
        name: "Term & Conditions",
        link: "/terms",
      },
      {
        name: "Privacy Policy",
        link: "/privacy-policies",
      },
    ],
  },
];

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const timeoutRef = useRef(null);

  const toggleNavbar = () => setIsOpen(!isOpen);

  const handleMouseEnter = useCallback((index) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setActiveDropdown(index);
  }, []);

  const handleMouseLeave = useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      setActiveDropdown(null);
    }, 300);
  }, []);

  const NavLink = ({ item, index, isMobile }) => (
    <div
      className={`relative ${isMobile ? "" : "ml-3"}`}
      onMouseEnter={() => !isMobile && handleMouseEnter(index)}
      onMouseLeave={() => !isMobile && handleMouseLeave()}
    >
      <div
        className={`flex items-center px-2 py-1 text-lg font-medium text-white focus:outline-none cursor-pointer ${
          isMobile ? "w-fit" : ""
        } ${
          item.name === "Get started"
            ? "bg-orange rounded-full px-4"
            : "rounded-md hover:bg-blue-800 transition-colors duration-200"
        }`}
        onClick={() =>
          isMobile &&
          item.dropdownItems.length > 0 &&
          setActiveDropdown(activeDropdown === index ? null : index)
        }
      >
        {item.name}
        {item.dropdownItems.length > 0 && (
          <ChevronDown
            className={`ml-1 h-4 w-4 duration-200 linear ${
              activeDropdown === index ? "rotate-180" : ""
            }`}
          />
        )}
      </div>
      {item.dropdownItems.length > 0 &&
        (isMobile ? activeDropdown === index : activeDropdown === index) && (
          <div
            className={`${
              isMobile ? "bg-blue" : "fixed z-50 top-[80px] left-0 w-full p-2 "
            }`}
          >
            <div className="flex lg:justify-end justify-start w-full">
              <div
                className="md:w-fit w-full flex md:px-8 md:p-4 p-2 max-w-[1440px] bg-blue/80 rounded-3xl"
                style={{
                  backdropFilter: "blur(12px)",
                }}
              >
                <div className={`w-full`}>
                  {!isMobile && (
                    <h2
                      className={`mx-2.5 font-bold mb-8 border-b-2 w-fit text-white`}
                    >
                      {item.name}
                    </h2>
                  )}

                  <h5 className="mx-3 font-bold mb-1 underline text-orange">
                    {" "}
                    {item.firstHead && item.firstHead}
                  </h5>
                  <div
                    className={` ${
                      item.name === "Company" ? "xl:columns-4" : "xl:columns-3"
                    } md:columns-2 sm:columns-3 xl:gap-4 gap-8 *:mb-4 md:w-fit w-full`}
                  >
                    {item.dropdownItems.map((dropdownItem, dropdownIndex) => (
                      <Link
                        key={dropdownIndex}
                        to={dropdownItem.link}
                        onClick={() => setIsOpen(false)}
                        className="group"
                      >
                        <div className={`space-y-2 px-3 py-1 rounded-lg`}>
                          <h4
                            className={`${
                              isMobile
                                ? "text-sm text-white"
                                : "text-base xl:text-lg font-bold text-white/70 group-hover:text-white transition-colors duration-200"
                            } flex gap-1 sm:items-start items-center capitalize`}
                          >
                            {isMobile && (
                              <span className="lg:mt-0 sm:mt-1">
                                <ArrowBigRightDash
                                  size={isMobile ? 12 : 20}
                                  color="#fff"
                                />
                              </span>
                            )}
                            {dropdownItem.name}
                          </h4>
                        </div>
                      </Link>
                    ))}
                  </div>

                  {item.secondHead && (
                    <h5 className="mx-3 font-bold mb-1 underline text-orange mt-4">
                      {item.secondHead}
                    </h5>
                  )}

                  <div className="xl:columns-4 md:columns-2 sm:columns-3 xl:gap-4 gap-8 *:mb-4 md:w-fit w-full">
                    {/* Second dropdown */}
                    {item.secondDropdownItems &&
                      item.secondDropdownItems.length > 0 && (
                        <>
                          {item.secondDropdownItems.map(
                            (secondDropdownItem, secondDropdownIndex) => (
                              <Link
                                key={secondDropdownIndex}
                                to={secondDropdownItem.link}
                                onClick={() => setIsOpen(false)}
                                className="group"
                              >
                                <div
                                  className={`space-y-2 px-3 py-1 rounded-lg`}
                                >
                                  <h4
                                    className={`${
                                      isMobile
                                        ? "text-sm text-white"
                                        : "text-base xl:text-lg font-bold text-white/70 group-hover:text-white transition-colors duration-200"
                                    } flex gap-1 sm:items-start items-center  `}
                                  >
                                    {isMobile && (
                                      <span className="lg:mt-0 sm:mt-1">
                                        <ArrowBigRightDash
                                          size={isMobile ? 12 : 20}
                                          color="#fff"
                                        />
                                      </span>
                                    )}
                                    {secondDropdownItem.title}
                                  </h4>
                                </div>
                              </Link>
                            )
                          )}
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );

  return (
    <nav className="bg-blue">
      <div className="max-w-[1980px] mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex h-[80px] justify-between items-center">
          <div className="flex-shrink-0 flex items-center">
            <Link to="/" className="text-2xl font-bold text-white">
              <p className="flex items-center gap-2 border border-gray-500 px-4 py-2 rounded-xl">
                <img
                  src="/assets/logo/fav.png"
                  alt=""
                  className="h-[24px] aspect-square"
                />
                <span>EazyComply</span>
              </p>
            </Link>
          </div>
          <div className="hidden lg:flex items-center">
            <Link
              to={"/industries/data-privacy-protection"}
              className="xl:block hidden text-white bg-orange px-4 py-1 rounded-full mr-6 font-medium hover:bg-orange-600 transition-colors duration-200"
            >
              DPDP Compliance
            </Link>
            <Link
              to={"/industries/environmental-social-governance"}
              className="xl:block hidden text-white bg-orange px-4 py-1 rounded-full font-medium hover:bg-orange-600 transition-colors duration-200"
            >
              ESG Compliance
            </Link>
            {navItems.map((item, index) => (
              <NavLink key={index} item={item} index={index} isMobile={false} />
            ))}
            <Link
              to={"/pricing"}
              className="text-white bg-orange px-4 py-1 rounded-full ml-4 font-medium hover:bg-orange-600 transition-colors duration-200"
            >
              Get started
            </Link>
          </div>
          <div className="lg:hidden flex items-center">
            <button
              onClick={toggleNavbar}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
            >
              <svg
                className="h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={
                    isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"
                  }
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="lg:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {navItems.map((item, index) => (
              <NavLink key={index} item={item} index={index} isMobile={true} />
            ))}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
