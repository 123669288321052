import React, { useState, useRef } from "react";
import Transition from "../../comps/transition";
import { Link } from "react-router-dom";
import { Arrow, Call, Mail } from "../../comps/svg";
import Swal from "sweetalert2";

const Contact = () => {
  const [formStatus, setFormStatus] = useState("idle");
  const formRef = useRef(null);

  const onSubmit = async (event) => {
    event.preventDefault();
    setFormStatus("submitting");
    const formData = new FormData(event.target);

    formData.append("access_key", "b09c151e-3558-479e-bccd-2c120a712f08");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    try {
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: json,
      }).then((res) => res.json());

      if (res.success) {
        setFormStatus("success");
        Swal.fire({
          title: "Success",
          text: "Thank you for choosing us. Our team will reach you shortly.",
          icon: "success",
        });
        // Reset the form
        if (formRef.current) {
          formRef.current.reset();
        }
      } else {
        setFormStatus("error");
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    } catch (error) {
      setFormStatus("error");
      console.error("Error submitting form:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An error occurred while submitting the form.",
      });
    }

    setTimeout(() => {
      setFormStatus("idle");
    }, 2000);
  };

  return (
    <div className="px-4 my-32">
      <div className="max-w-[1440px] mx-auto flex md:flex-row flex-col *:w-full gap-6 relative">
        {/* Link */}
        <div className=" flex flex-col gap-4 md:px-6 md:py-8">
          <h2 className=" font-bold sm:text-5xl text-3xl px-0.5">
            Get in Touch with Us
          </h2>

          <p className=" px-1 max-w-[600px]">
            Reach out to us for inquiries, support, or partnership
            opportunities. We're here to assist you!
          </p>

          <div className=" flex flex-col gap-4 *:bg-white">
            <div className=" flex justify-between items-center p-2 rounded-xl">
              <div className=" flex items-center gap-4">
                <span className=" bg-[#00000010] p-3 rounded-xl">
                  <Mail size={24} color={"#1f1f1f"} />
                </span>
                <p className=" font-medium ">
                  You can email us here <br /> info@eazycomply.in
                </p>
              </div>
              <Link
                to={"mailto:info@eazycomply.in"}
                className="overflow-hidden"
              >
                <div className=" p-3 border border-zinc-600 rounded-full hover:scale-110 hover:rotate-[24deg] duration-150">
                  <Arrow size={24} color={"#1f1f1f"} />
                </div>
              </Link>
            </div>

            <div className=" flex justify-between items-center p-2 border rounded-xl">
              <div className=" flex items-center gap-4">
                <span className=" bg-[#00000010] p-3 rounded-xl">
                  <Call size={24} color={"#1f1f1f"} />
                </span>
                <p className=" font-medium ">
                  Or give us a call
                  <br />
                  Book a Call
                </p>
              </div>
              <Link to={"tel:9899322648"} className="overflow-hidden">
                <div className=" p-3 border border-zinc-600 rounded-full hover:scale-110 hover:rotate-[24deg] duration-150">
                  <Arrow size={24} color={"#1f1f1f"} />
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/* Link */}
        {/* Form */}

        <form
          ref={formRef}
          onSubmit={onSubmit}
          className="md:px-6 md:py-8 p-4 md:mt-0 mt-8 rounded-xl bg-white"
        >
          <h2 className=" font-bold sm:text-5xl text-3xl">Send Us a Message</h2>
          <p className=" mt-3 mb-8">
            Use our convenient contact form to reach out with questions,
            feedback, or collaboration inquiries.
          </p>

          <div className=" flex md:flex-row flex-col gap-2 *:w-full">
            <input
              type="text"
              placeholder="Name"
              name="name"
              required
              className=" bg-[#00000010] rounded-xl outline-none p-4"
            />
            <input
              type="text"
              placeholder="Email"
              name="email"
              required
              className=" bg-[#00000010] rounded-xl outline-none p-4"
            />
          </div>
          <div className=" my-2">
            <textarea
              name="message"
              id=""
              placeholder="Message"
              required
              className=" bg-[#00000010] w-full rounded-xl outline-none p-4 min-h-[150px] max-h-[600px]"
            ></textarea>
          </div>

          <style jsx>{`
            @keyframes pulse {
              0% {
                transform: scale(1);
              }
              50% {
                transform: scale(1.05);
              }
              100% {
                transform: scale(1);
              }
            }
            .animate-pulse {
              animation: pulse 0.5s ease-in-out;
            }
          `}</style>

          <button
            className={`w-full rounded-xl outline-none p-4 text-center text-white font-bold duration-150 ${
              formStatus === "submitting"
                ? "bg-gray-400 cursor-not-allowed"
                : formStatus === "success"
                ? "bg-green-500 hover:bg-green-600 animate-pulse"
                : formStatus === "error"
                ? "bg-red-500 hover:bg-red-600 animate-pulse"
                : "bg-blue text-white hover:bg-[#4a5fa0e3] duration-200"
            }`}
            disabled={formStatus === "submitting"}
          >
            {formStatus === "submitting"
              ? "Sending..."
              : formStatus === "success"
              ? "Sent Successfully!"
              : formStatus === "error"
              ? ""
              : "Send Message"}
          </button>
        </form>

        {/* Form */}
      </div>
    </div>
  );
};

export default Transition(Contact);
