import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Transition from "./transition";
import CTA from "./CTA";
import { ToLink } from "./svg";

const ServicePage = ({ type }) => {
  const { id } = useParams();
  const service = type.find((s) => s.id === id);

  if (!service) {
    return (
      <div className="min-h-[70vh] flex items-center justify-center text-7xl p-2">
        Service not found!
      </div>
    );
  }

  return (
    <>
      <div
        className=" md:px-8 px-4 py-8 relative md:min-h-[600px] min-h-[500px] flex flex-col justify-end gap-4"
        style={{
          borderImage:
            "fill 1 linear-gradient(90deg, rgba(13,34,71,0.5) 0%, rgba(203,254,255,0.2) 100%)",
        }}
      >
        <div className=" absolute inset-0 h-full w-full -z-10">
          <img
            src={`/assets/servicePage/${
              service.back ? service.back : "expertise.jpg"
            }`}
            alt=""
            className={`h-full w-full object-cover ${
              service.center ? "object-center" : "object-top"
            } `}
          />
        </div>

        <div className="text-white max-w-[1440px] w-full mx-auto">
          <h1 className=" font-bold mb-4 -ml-0.5 max-w-[800px]">
            {service.title}
          </h1>
          <p className=" text-lg max-w-[768px] font-medium">{service.des}</p>
        </div>
      </div>

      {/* Service Description */}
      <div className="md:px-8 px-4 py-20 bg-blue text-white">
        <div className="max-w-[1440px] w-full mx-auto flex lg:flex-row flex-col justify-between gap-4">
          <div className="flex flex-col gap-4 lg:w-[60%]">
            <h2 className=" font-bold">What is {service.title} ?</h2>
            <p className=" text-lg ">{service.answer}</p>

            <img
              src={`/assets/services/${service.img}`}
              alt=""
              className=" max-h-[500px] object-cover mt-4"
            />
          </div>

          <div className=" rounded-xl lg:w-[25%] h-fit bg-white p-8 text-dark ">
            <h2 className="font-bold mb-8 "> Other Expertise </h2>

            <div className="">
              {type.map((s, i) => {
                return (
                  <Link
                    to={`/${s.linkTo ? s.linkTo : "industries"}/${s.id}`}
                    key={i}
                    className={` ${s.id === service.id ? " hidden " : ""} `}
                  >
                    <p className=" mb-4 hover:underline duration-100 uppercase flex items-start gap-2">
                      <span>
                        <ToLink color={"#1f1f1f"} size={24} />
                      </span>
                      <span className="font-medium text-[16px]">{s.title}</span>
                    </p>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* Risks and Solutions Accordion */}
      <div className="md:px-8 px-4 py-20 bg-orange">
        <div className="max-w-[1440px] w-full mx-auto">
          <h2 className="font-bold mb-6 text-white">Risks and Solutions</h2>
          {Object.entries(service.risk).map(([key, risk], index) => (
            <Accordion
              key={key}
              title={risk.heading}
              content={
                <>
                  <p className="mb-4">
                    <span className=" mr-2 font-bold"> Risk Associated: </span>
                    {risk.des}
                  </p>
                  <p>
                    <span className=" mr-2 font-bold"> Solution: </span>
                    {service.help[`help${index + 1}`]?.des ||
                      "Solution not available."}
                  </p>
                </>
              }
            />
          ))}
        </div>
      </div>

      <CTA />
    </>
  );
};

export default Transition(ServicePage);

const Accordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="rounded-xl bg-white overflow-hidden mb-2">
      <motion.button
        className={`flex justify-between items-center w-full p-4 text-left hover:bg-blue hover:text-white duration-150`}
        onClick={() => setIsOpen(!isOpen)}
        transition={{ duration: 0.2 }}
      >
        <p className="font-semibold">{title}</p>
        <motion.h3
          animate={{ rotate: isOpen ? 225 : 0 }}
          transition={{ duration: 0.3 }}
        >
          +
        </motion.h3>
      </motion.button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="p-4">{content}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
