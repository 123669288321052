import React from "react";
import Lenis from "lenis";
import Scroll from "./comps/scroll";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home/home";
import Footer from "./comps/footer";
import About from "./pages/about/about";
import Contact from "./pages/contact/contact";
import Platform from "./pages/platform/platform";
import Team from "./pages/about/comp/team";
import Soon from "./comps/comingsoon";
import Navbar from "./comps/nav";
import ServiceDetail from "./pages/services/comps/serviceDetail";
import ServicePage from "./comps/servicePage";
import FAQ from "./comps/FAQ";
import Price from "./pages/platform/comps/price";
import Privacy from "./pages/terms/privacy";
import Terms from "./pages/terms/terms";
import Connect from "./comps/connect";
import { services, stageService } from "./comps/data";

export default function App() {
  const lenis = new Lenis();

  function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }

  requestAnimationFrame(raf);
  return (
    <div>
      <Scroll />
      <Navbar />
      <Connect />

      <div className="">
        <Routes>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/platform" element={<Platform />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/pricing" element={<Price />} />
          <Route path="/privacy-policies" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />

          <Route
            path="/leadership"
            element={
              <>
                <Team /> <Contact />
              </>
            }
          />
          <Route
            path="/faqs"
            element={
              <>
                <FAQ /> <Contact />
              </>
            }
          />
          <Route path="/services/:id" element={<ServiceDetail />} />
          <Route
            path="/industries/:id"
            element={<ServicePage type={services} />}
          />
          <Route
            path="/stage/:id"
            element={<ServicePage type={stageService} />}
          />
          {/* <Route path="/stage/:id" element={<ServicePage type={} />} /> */}
          <Route path="/*" element={<Soon />} />
        </Routes>
      </div>

      <Footer />
    </div>
  );
}
