import React from "react";
import { platformFeatures } from "../../../comps/data";

export default function Features() {
  return (
    <div className="px-4 py-12 mt-20 bg-blue ">
      {/* Features */}
      <div className=" max-w-[1440px] mx-auto w-full">
        <h1 className=" w-full text-center font-bold text-white">
          Discover EazyComply Powerful Features
        </h1>

        <div className=" mt-12 grid lg:grid-cols-3 sm:grid-cols-2 gap-4">
          {platformFeatures.map((feature, i) => {
            return (
              <div key={i} className=" p-4 bg-white rounded-xl ">
                <div className="flex gap-4">
                  <span className="flex w-fit h-fit p-2 rounded-full bg-dark ">
                    {feature.icon}
                  </span>
                  <h2 className=" font-bold md:text-3xl text-xl items-center content-center flex flex-wrap gap-1.5">
                    {feature.title.split(" ").map((word, wordIndex)=> {
                      return(
                        <span key={wordIndex} className=""> {word} </span>
                      )
                    })}
                    </h2>
                </div>
                <p className=" mt-4">{feature.des}</p>
              </div>
            );
          })}
        </div>
      </div>
      {/* Features */}
    </div>
  );
}
