import React from "react";
import Transition from "../../comps/transition";
import CTA from "../../comps/CTA";

const Terms = () => {
  return (
    <div>
      <div
        className=" md:px-8 px-4 py-12 md:min-h-[600px] min-h-[500px] w-full flex items-end justify-center relative z-0"
        style={{
          borderImage:
            "fill 1 linear-gradient(90deg, rgba(13,34,71,1) 0%, rgba(203,254,255,0.2) 100%)",
        }}
      >
        <img
          src="/assets/photos/terms.svg"
          alt=""
          className=" absolute inset-0 w-full h-full -z-10 object-cover"
        />
        <div className=" mt-32 flex flex-col max-w-[1440px] mx-auto justify-end w-full text-dark *:text-center">
          <h2 className=" font-bold mt-32">TERMS AND CONDITIONS</h2>

          <h4 className="mt-4">
            <span className=" font-bold">Latest Update on:</span> 16th August
            2024
          </h4>
        </div>
      </div>

      {/* Introduction */}
      <BlDiv>
        <h2 className=" font-bold">Introduction</h2>
        <p>
          We are a technology company which is engaged in the business of
          providing software solutions, building intuitive and predictive
          software to make work mechanics simple and easy to use (hereinafter
          referred to as “EAZYCOMPLY”). It provides software solutions to the
          Clients for ensuring compliances with the statutory regulations as per
          the law. EAZYCOMPLY is a product which allows subscribers/ Client to
          dynamically drive the compliance performance, view compliance
          dashboards, generate compliance reports, repository of compliance
          documents and to use a centralized audit tool.
        </p>
      </BlDiv>
      {/* Introduction */}

      {/* Purpose */}
      <OrDiv>
        <h2 className=" font-bold">Purpose</h2>
        <p>
          Our mission is to provide an online plug and play compliance
          application that makes “Being Compliant” easy for organizations.
        </p>
      </OrDiv>
      {/* Purpose */}

      {/* Use of Website */}
      <BlDiv>
        <h2 className=" font-bold"> Use of Website</h2>
        <p>The Website shall only be used for lawful purposes.</p>
        <p>
          The use or access of the Website does not include any or all rights to
          use or sell any of the content available on the Website.
        </p>
        <p>
          HAWK EYE reserves its right to monitor the behaviour on its website
          and terminate or restrict the use of the Website, in case any user is
          found in breach of the terms and conditions set out herein.
        </p>
      </BlDiv>
      {/* Use of Website */}

      {/* Account Terms */}
      <OrDiv>
        <h2 className=" font-bold"> Account Terms</h2>
        <p>You just be 18 years or older to use this Service.</p>
        <p>
          You must provide you full legal name, a valid email address and any
          other required information to complete the sign – up process. When You
          create an account with Us, You must provide Us information that is
          accurate, complete, and current at all times. Failure to do so
          constitutes a breach of the Terms, which may result in immediate
          termination of Your account on Our Service
        </p>
        <p>
          You acknowledge that you have read, understood, and accepted this
          Agreement and you have the authority to act on behalf of any person or
          entity for whom you are using the Services, and you are deemed to have
          agreed to this Agreement on behalf of any entity for whom you use the
          Services.
        </p>
        <p>
          You agree to not disclose your password or user ID to any third party.
          You must notify Us immediately upon becoming aware of any breach of
          security or unauthorized use of Your account.
        </p>
        <p>
          You may not use as a username the name of another person or entity or
          that is not lawfully available for use, a name or trademark that is
          subject to any rights of another person or entity other than You
          without appropriate authorization or a name that is otherwise
          offensive, vulgar or obscene.
        </p>
        <p>
          HAWK EYE may communicate with you via email regarding your account,
          system updates or other issues related to your account.
        </p>
        <p>
          You are responsible for all content posted and activity that occurs
          under your account.z
        </p>
      </OrDiv>
      {/* Account Terms */}

      {/* Payment Terms */}
      <BlDiv>
        <h2 className=" font-bold">Payment Terms </h2>
        <p>
          We will charge you a standard fee based on your account plan. The
          Service is billed in advance and is non–refundable. There will be no
          refunds or credits for partial months of service or refunds made
          should You not use the Service during a period of time when your
          account is open. No exceptions will be made.
        </p>
      </BlDiv>
      {/* Payment Terms */}

      {/* Modification to the Services */}
      <OrDiv>
        <h2 className=" font-bold"> Modification to the Services</h2>
        <p>
          HAWK EYE reserves the right to modify, suspend or discontinue the
          Service at any time for any reason with or without notice.
        </p>
      </OrDiv>
      {/* Modification to the Services */}

      {/* Cancellation and Termination */}
      <BlDiv>
        <h2 className=" font-bold"> Cancellation and Termination</h2>
        <p>
          You are solely responsible for the proper cancellation of Your
          account. You may cancel your account at any time by contacting your
          Account Manager by sending an email to the official contact details.
          You can cancel at any time, but you will remain liable for all charges
          accrued up to that time, including full monthly charges for the month
          which you discontinued service. You will not be charged again.
        </p>
        <p>HAWK EYE reserves the right to:</p>

        <ul className=" mx-auto max-w-[1440px] w-[95%] list-disc list-outside *:mb-2">
          <li>
            <p>
              modify or discontinue, temporarily or permanently, the Service (or
              any part thereof)
            </p>
          </li>
          <li>
            <p>
              refuse any and all, current and future use of the Service, suspend
              or terminate your account (any part thereof) or use of the Service
              and remove and discard any of your content in the Service, for any
              reason, including, if HAWK EYE believes that you have violated
              these Terms Of Service. HAWK EYE will use all reasonable efforts
              to contact you directly via email to warn you prior to suspension
              or termination of your account. Any suspected fraudulent, abusive,
              or illegal activity that may be grounds for termination of your
              use of Service, may be referred to appropriate law enforcement
              authorities. HAWK EYE shall not be liable to you or any third
              party for any modification, suspension or discontinuation of the
              Service.
            </p>
          </li>
        </ul>
      </BlDiv>
      {/* Cancellation and Termination */}

      {/* Copyright and Ownership */}
      <OrDiv>
        <h2 className=" font-bold"> Copyright and Ownership</h2>
        <p>
          HAWK EYE owns the intellectual property rights to any and all
          protectable components of the Service, including but not limited to
          the name of the Service, artwork and end-user interface elements,
          pre-configured compliance data contained within the Service, many of
          the individual features, and the related documentation. You may not
          copy, modify, adapt, reproduce, distribute, reverse engineer,
          decompile, or dissemble any aspect of the Service which HAWK EYE owns.
        </p>
      </OrDiv>
      {/* Copyright and Ownership */}

      {/* Content Policy */}
      <BlDiv>
        <h2 className=" font-bold"> Copyright and Ownership</h2>
        <p>
          HAWK EYE holds and reserves all intellectual property rights of any or
          all content posted on the Website.
        </p>
        <p>
          {" "}
          Users may not therefore use such content in any way that is not
          necessary or implicit in the proper use of the Service and the
          Website.
        </p>
        <p>
          {" "}
          In particular, but without limitation, Users may not copy, download,
          share (beyond the limits set forth below), modify, translate,
          transform, publish, transmit, sell, sub–license, edit, transfer/
          assign to third parties, or create derivative works from the content
          available on the Website, nor allow any third party to do so through
          the User or their device, even without the User’s knowledge.
        </p>
        <p>
          Where explicitly provided on the Website, the User may download, copy
          and/ or share limited content available through the Website for its
          sole personal and non–commercial use and provided that the copyright
          attributions and all the other attributions requested by HAWK EYE are
          correctly implemented.
        </p>
      </BlDiv>
      {/* Content Policy*/}

      {/* Links to other websites */}
      <OrDiv>
        <h2 className=" font-bold"> Links to other websites</h2>
        <p>
          The Website may contain links to third–party websites or services that
          are not owned or controlled by HAWK EYE.
        </p>
        <p>
          HAWK EYE has no control over, and assumes no responsibility for, the
          content, privacy policies or practices of any third-party websites or
          Services. You further acknowledge and agree that HAWK EYE shall not be
          responsible or liable, directly or indirectly, for any damage or loss
          caused or alleged to be caused by or in connection with the use of or
          reliance on any such content, goods or services available on or
          through any such web sites or services. 10.3. We strongly advise You
          to read the terms and conditions and privacy policies of any
          third-party websites or services that You visit.
        </p>
      </OrDiv>
      {/* Links to other websites */}

      {/* General Conditions and Restrictions */}
      <BlDiv>
        <h2 className=" font-bold">General Conditions and Restrictions</h2>
        <p>
          Your use of the Service and the Website, including any content,
          information or functionality contained within it, is provided "as is"
          with no representation or warranties of any kind, either expressed or
          implied, including but not limited to, the implied warranties of
          merchantability, fitness for a particular purpose and non –
          infringement. You assume total responsibility and risk for your use of
          this Service.
        </p>
        <p>
          You agree not to resell, duplicate, reproduce or exploit, copy, create
          a derivative work of, reverse engineer, reverse assemble, disassemble,
          or decompile the Software or any part thereof or otherwise attempt to
          discover any source code or modify the Software in any manner or form
          any part of the Service without the express written permission of HAWK
          EYE.
        </p>
        <p>
          You may not use the service to store, host, or send unsolicited email
          (spam) or SMS messages.
        </p>
        <p>
          HAWK EYE will maintain commercially acceptable administrative,
          physical and technical safeguards to protect the security,
          confidentiality and integrity of your data.
        </p>
        <p>
          You may not use the service to transmit any viruses, worms, or
          malicious content.
        </p>
        <p>
          You shall not permit any affiliate, User or any other third party
          obtain unauthorized access to the Software (including without
          limitation permitting access to or use of the Software via another
          system or tool, the primary effect of which is to enable input of
          requests or transactions by other than authorized Users).
        </p>
        <p>HAWK EYE makes no warranties regarding (vi)</p>

        <ul className=" mx-auto max-w-[1440px] w-[95%] list-disc list-outside *:mb-2">
          <li>
            <p>your ability to use the Service</p>
          </li>
          <li>
            your satisfaction with the Service<p></p>
          </li>
          <li>
            <p>
              that the Service will be available at all times, uninterrupted,
              and error-free
            </p>
          </li>
          <li>
            <p>
              the accuracy of mathematical calculations performed by the Service
            </p>
          </li>
          <li>
            <p>
              the accuracy of the acts and laws contained in the Service and{" "}
            </p>
          </li>
          <li>
            <p>that bugs or errors in the Service will be corrected.</p>
          </li>
        </ul>
        <p>
          HAWK EYE its affiliates and its sponsors are neither responsible nor
          liable for any direct, indirect, incidental, consequential, special,
          exemplary, and punitive or other damages arising out of or relating in
          any way to your use of the Service and Website. Your sole remedy for
          dissatisfaction with the Service is to stop using the Service and
          Website.
        </p>
        <p>
          If any provision of the Terms of Service is held invalid or otherwise
          unenforceable, the enforceability of the remaining provisions shall
          not be impaired thereby.
        </p>
        <p>
          HAWK EYE may, but have no obligation to, remove content and accounts
          containing content that we determine in our sole discretion are
          unlawful, offensive, threatening, libelous, defamatory, pornographic,
          obscene or otherwise objectionable or violates any party's
          intellectual property or these Terms of Service.
        </p>
        <p>
          The failure of HAWK EYE to exercise any right provided for herein
          shall not be deemed a waiver of any right hereunder. The Terms of
          Service sets forth the entire understanding between you and HAWK EYE
          as to the Service and supersedes any prior agreements between you and
          HAWK EYE (including, but not limited to, prior versions of the Terms
          of Service).
        </p>
      </BlDiv>
      {/* General Conditions and Restrictions */}

      {/* Governing Law */}
      <OrDiv>
        <h2 className=" font-bold">Governing Law</h2>
        <p>
          These Terms of Services shall be governed by the laws of Delhi, India
          without regard to the principles of conflicts of law. You hereby
          expressly agree to submit to the exclusive personal jurisdiction of
          the state courts of the State of Tamil Nadu for the purpose of
          resolving any dispute relating to your access to or use of the
          Service.
        </p>
      </OrDiv>
      {/* Governing Law */}
      {/* Changes to the Terms and Conditions */}
      <BlDiv>
        <h2 className=" font-bold">Changes to the Terms and Conditions</h2>
        <p>
          HAWK EYE reserves the right, at its sole discretion, to modify or
          replace these Terms at any time.
        </p>
      </BlDiv>
      {/* Changes to the Terms and Conditions */}
      {/* Severability and Waiver */}
      <OrDiv>
        <h2 className=" font-bold">Severability and Waiver</h2>
        <p>
          <span className=" font-bold text-white">Severability</span> – if any
          provision of these Terms is held to be unenforceable or invalid, such
          provision will be changed and interpreted to accomplish the objectives
          of such provision to the greatest extent possible under applicable law
          and the remaining provisions will continue in full force and effect.
        </p>
        <p>
          <span className=" font-bold text-white">Waiver</span> – Except as
          provided herein, the failure to exercise a right or to require
          performance of an obligation under these Terms shall not effect a
          person's ability to exercise such right or require such performance at
          any time thereafter nor shall the waiver of a breach constitute a
          waiver of any subsequent breach.
        </p>
      </OrDiv>
      {/* Severability and Waiver */}

      <CTA />
    </div>
  );
};

export default Transition(Terms);

const OrDiv = ({ children, className }) => {
  return (
    <div className={`md:px-8 px-4 py-12 bg-orange text-[#111] ${className}`}>
      <div className="flex flex-col gap-6 mx-auto w-full max-w-[1440px]">
        {children}
      </div>
    </div>
  );
};

const BlDiv = ({ children, className }) => {
  return (
    <div className={`md:px-8 px-4 py-12 bg-blue text-[#fff] ${className}`}>
      <div className="flex flex-col gap-6 mx-auto w-full max-w-[1440px]">
        {children}
      </div>
    </div>
  );
};
