import React from "react";
import { Link } from "react-router-dom";
import { Members } from "../../../comps/data";
import { Linkedin } from "../../../comps/svg";

export default function Team() {
  return (
    <div className=" my-32">
      <h1 className=" w-fit mx-auto mb-12 font-bold"> Meet Our Leaders </h1>
      <div className=" max-w-[1440px] mx-auto w-fit grid md:grid-cols-3 sm:grid-cols-2 gap-4">
        {Members.map((member, i) => {
          return (
            <div
              key={i}
              className="rounded-xl p-4 flex flex-col gap-2 bg-white"
            >
              <img
                src={member.img}
                alt=""
                className=" h-[320px] w-full object-cover object-top rounded-xl"
              />

              <div className=" capitalize mx-1 flex items-end justify-between gap-4">
                <div>
                  <p className=" mb-1"> {member.name} </p>
                  <p> {member.role} </p>
                </div>

                <div>
                  <Link
                    to={member.link}
                    target="_blank"
                    noopener="true"
                    noreferrer="true"
                  >
                    <Linkedin size={32} color={"#1f1f1f"} />
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
