import React from "react";

export default function Header() {
  return (
    <>
      <div className="px-4">
        {/* Header */}
        <div className=" max-w-[1440px] mx-auto w-full flex lg:flex-row flex-col gap-4 *:w-full">
          <div className=" flex flex-col justify-center">
            <h1 className=" font-bold mb-6">
              <span className=" text-orange">Secure</span> Your{" "}
              <span className="text-orange">Future</span> with Integrated ERM,
              GRC, and ESG Solutions
            </h1>
            <p>
              Unlock your potential with our cutting-edge software that paves
              the way to success. Trust us to streamline your compliance needs,
              so you can concentrate on fortifying your company and driving
              growth. We take care of the fine print, so you can focus on the
              big picture!
            </p>
          </div>

          <div>
            <img
              src="/assets/photos/about.avif"
              alt=""
              className="h-[400px] w-full object-cover rounded-xl"
            />
          </div>
        </div>
        {/* Header */}
      </div>

      {/* intro */}
      <div className=" bg-blue text-white px-4 py-12 mt-8">
        <div className="max-w-[1440px] mx-auto w-full flex flex-col gap-8">
          <h1 className=" font-bold min-w-fit">About EazyComply</h1>

          <div>
            <h4>
              EazyComply is a cutting-edge compliance management solution
              designed to streamline and simplify the complex processes
              associated with regulatory compliance in various industries. In an
              ever-evolving landscape of rules and regulations, businesses face
              increasing challenges in maintaining adherence to legal and
              industry standards. EazyComply addresses these challenges by
              providing a comprehensive, user-friendly platform that ensures
              organizations remain compliant while minimizing the administrative
              burden.
            </h4>

            <img
              src="/assets/servicePage/about.jpg"
              alt=""
              className=" my-12 object-cover max-h-[600px] w-full"
            />

            <h4 className=" my-4">
              EazyComply is dedicated to empowering businesses with seamless
              compliance management, so they can focus on what truly
              matters—driving their core operations forward without the stress
              of regulatory hurdles.
            </h4>
            <h4>
              We are committed to offering a robust, scalable, and intuitive
              solution that adapts to the specific compliance needs of each
              client, nurturing a culture of proactive compliance and continuous
              improvement.
            </h4>
          </div>
        </div>
      </div>
      {/* intro */}
    </>
  );
}
