import React from "react";

export default function Header() {
  return (
    <div className="px-4">
      {/* Header */}
      <div className=" max-w-[1440px] mx-auto w-full flex lg:flex-row flex-col gap-4 *:w-full">
        <div className=" flex flex-col justify-center">
          <h1 className=" font-bold mb-4">
            <span className=" text-orange"> Simplify </span>Compliance,
            <span className=" text-orange"> Amplify </span> Success
          </h1>
          <p>
            Unlock The business’s potential with software designed to
            streamline processes and drive sustainable growth.
          </p>
        </div>

        <div>
          <img
            src="/assets/photos/p_header.svg"
            alt=""
            className=" h-[400px] w-full object-contain rounded-xl"
          />
        </div>
      </div>
      {/* Header */}

      {/* intro */}
      <div className="max-w-[1440px] mx-auto w-full mt-20 flex flex-col gap-8">
        <h1 className=" font-bold min-w-fit">
          Why <span className="text-orange">EazyComply</span>
        </h1>

        <div>
          <h4>
            In today’s fast-paced business environment, keeping up with the
            ever-changing regulatory landscape can be overwhelming. EazyComply
            is here to change that. EazyComply platform is a cutting-edge compliance
            management solution, designed to help businesses navigate the
            complex world of regulations with ease.
          </h4>
          <h4 className=" my-4">
            Whether you’re a small startup or a large enterprise, EazyComply
            provides you with the tools you need to stay compliant, reduce
            risks, and focus on what truly matters—growing your business. With
            EazyComply, managing compliance is no longer a burden but a seamless
            part of your operational strategy.
          </h4>
        </div>
      </div>
      {/* intro */}
    </div>
  );
}
