import React from "react";
import Transition from "../../comps/transition";
import Header from "./comps/header";
import Features from "./comps/features";
import Contact from "../contact/contact";
import Right from "./comps/right";
import FAQ from "../../comps/FAQ";

const Platform = () => {
  return (
    <div className=" mt-32">
      <Header />
      <Features />
      <Right />
      <FAQ />
      <Contact />
    </div>
  );
};

export default Transition(Platform);
