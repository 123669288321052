import React from "react";
import Transition from "../../comps/transition";
import Header from "./comp/header";
import Features from "./comp/features";
import Services from "./comp/services";
import Contact from "../contact/contact";
import IM from "./comp/IM";

const Home = () => {
  return (
    <div className="mt-32">
      <Header />
      <Features />
      <IM/>
      <Services />
      <Contact />
    </div>
  );
};

export default Transition(Home);
