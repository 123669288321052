import React from "react";
import Transition from "../../comps/transition";
import Header from "./comp/header";
import Feedback from "./comp/feedback";
import Model from "./comp/Model";
import Contact from "../contact/contact";

const About = () => {
  return (
    <div className=" mt-32">
      <Header />
      <Model />
      <Feedback />
      <Contact />
    </div>
  );
};

export default Transition(About);
