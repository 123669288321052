import React, { useRef } from "react";
import { useScroll, motion, useTransform } from "framer-motion";
import { Chart, Design, HandShake, Rocket } from "../../../comps/svg";
export default function Model() {
  const container = useRef(null);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start 0.8", "end 0.6"],
  });

  const model = [
    {
      title: "Engage",
      data: {
        first: "Define clear success criteria.",
        second: "Map out your processes and risk universe.",
        third: "Align with your organization's vision and leadership.",
      },
      icon: <HandShake size={36} color={"#fff"} />,
    },

    {
      title: "Design",
      data: {
        first: "Assess risks and create industry-standard templates",
        second: "Tailor the process flow to match your business needs.",
        third:
          "Conduct a needs analysis based on your financial and operations.",
      },
      icon: <Design size={36} color={"#fff"} />,
    },

    {
      title: "Execute",
      data: {
        first: "Onboard your team and provide training.",
        second: "Conduct audits and generate scorecards.",
        third: "Automate processes and ensure quality reporting.",
      },
      icon: <Rocket size={36} color={"#fff"} />,
    },

    {
      title: "Improve",
      data: {
        first: "Map and resolve Corrective Action Plans (CAPs).",
        second: "Continuously monitor and assess risks",
        third: "Regularly update risk profiles and enhance decision-making.",
      },
      icon: <Chart size={36} color={"#fff"} />,
    },
  ];
  return (
    <div className=" px-4 pt-12 py-32 bg-orange">
      <div className="max-w-[1440px] w-full mx-auto relative">
        <h1 className="md:text-9xl sm:text-8xl text-6xl -mt-32 text-white font-bold flex flex-col items-center justify-center mb-20 sticky top-16 min-h-[500px] h-[50vh]">
          Our Approach
        </h1>

        <div className="">
          {/* Model */}
          <div ref={container} className="relative -mb-12">
            <div className=" absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] w-[2px] h-[98%] overflow-hidden bg-gray-300 rounded-full">
              <motion.div
                className=" h-full w-full bg-[#111]"
                style={{
                  scaleY: scrollYProgress,
                  transformOrigin: "top",
                }}
              />
            </div>

            {model.map((item, i) => {
              return (
                <div
                  key={i}
                  className={`p-2 min-h-[400px] flex items-center md:justify-start justify-center relative ${
                    i % 2 === 0 ? " flex-row " : " flex-row-reverse"
                  }`}
                >
                  <div className=" absolute inset-0 md:grid hidden place-content-center z-10">
                    <div className=" aspect-square h-[20px] bg-gray-200 rounded-full overflow-hidden">
                      <DynamicScaleComponent
                        i={i}
                        scrollYProgress={scrollYProgress}
                      />
                    </div>
                  </div>

                  <motion.div
                    initial={{
                      opacity: 0,
                      x: i % 2 === 0 ? -20 : 20,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                    }}
                    transition={{
                      type: "ease",
                      duration: 0.5,
                    }}
                    viewport={{
                      once: true,
                      amount: 1,
                    }}
                    className=" md:max-w-[40%] bg-blue text-white min-h-[400px] rounded-xl flex flex-col justify-center gap-2 w-full p-4"
                  >
                    <h1 className="font-bold mb-8 flex items-center gap-4 ">
                      <span>{item.icon}</span>
                      <span>{item.title}</span>
                    </h1>
                    <h4 className=" pl-1.5">{item.data.first}</h4>
                    <h4 className=" pl-1.5">{item.data.second}</h4>
                    <h4 className=" pl-1.5">{item.data.third}</h4>
                  </motion.div>
                </div>
              );
            })}
          </div>
          {/* Model */}
        </div>
      </div>
    </div>
  );
}

const DynamicScaleComponent = ({ i, children, scrollYProgress }) => {
  const inputRange = [1 / 9 + i * (1 / 4), 1 / 8 + i * (1 / 4)];
  const scale = useTransform(scrollYProgress, inputRange, [0, 1]);
  return (
    <motion.div
      className="bg-[#111] rounded-full h-full w-full"
      style={{ scale }}
    >
      {children}
    </motion.div>
  );
};
