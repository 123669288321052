import {
  AI,
  Analytics,
  Book,
  Monitor,
  Puzzle,
  Slider,
  Support,
  Upload,
  Zap,
  Lock,
  Doc,
  Shield,
  CheckList,
  Refresh,
  HandShake,
  Design,
} from "./svg";

export const features = [
  {
    title: "Compliance Platform",
    description:
      "The EazyComply platform streamlines compliance and risk management operations.",
    learnMoreLink: "/platform",
    icon: <Slider size={24} color={"#fff"} />,
  },
  {
    title: "Smart Integrations",
    description:
      "Automate evidence collection across existing tools and systems, saving valuable time.",
    learnMoreLink: "/integrations",
    icon: <Puzzle size={24} color={"#fff"} />,
  },
  {
    title: "Framework Library",
    description:
      "Access a library of 100+ supported framework templates to kickstart compliance efforts.",
    learnMoreLink: "/frameworks",
    icon: <Book size={24} color={"#fff"} />,
  },
  {
    title: "Rapid Implementation",
    description:
      "Programs are up and running in weeks, not months, with expert support from EazyComply.",
    learnMoreLink: "/implementation",
    icon: <Zap size={24} color={"#fff"} />,
  },
  {
    title: "Real-time Monitoring",
    description:
      "Continuous compliance monitoring and instant alerts help stay ahead of risks.",
    learnMoreLink: "/monitoring",
    icon: <Monitor size={24} color={"#fff"} />,
  },
  {
    title: "24/7 Expert Support",
    description:
      "Round-the-clock assistance is available from a dedicated team of compliance specialists.",
    learnMoreLink: "/support",
    icon: <Support size={24} color={"#fff"} />,
  },
];

export const services = [
  {
    title: "Enterprise Risk Management",
    back: "ERM.jpg",
    center: true,
    id: "enterprise-risk-management",
    des: "Enterprise Risk Management (ERM) is a process used by organizations to identify, assess, manage, and monitor potential risks that could affect their ability to achieve their objectives.",
    img: "ERM.png",
    answer:
      "ERM is a comprehensive framework used by organizations to identify, assess, and manage all types of risks that could impact their ability to achieve strategic objectives. This includes financial, operational, strategic, and compliance risks. ERM involves continuous monitoring, risk prioritization, and implementing strategies to mitigate or exploit risks, ensuring a balanced approach to risk and opportunity management.",
    risk: {
      risk1: {
        heading: "Strategic Risks",
        des: "Risks that affect an organization's ability to achieve its long-term objectives, such as changes in market conditions, competition, or technological disruptions.",
      },
      risk2: {
        heading: "Operational Risks",
        des: "Risks associated with the organization's internal processes, systems, and people, including supply chain disruptions, IT failures, or human errors.",
      },
      risk3: {
        heading: "Financial Risks",
        des: "Risks related to the organization's financial health, including market volatility, credit risks, liquidity issues, or currency fluctuations. ",
      },
      risk4: {
        heading: "Compliance Risks",
        des: "Risks associated with legal and regulatory requirements, including changes in laws, industry standards, or potential legal actions.",
      },
    },
    help: {
      help1: {
        heading: "Risk Identification and Assessment",
        des: "EazyComply platform helps identify and evaluate potential risks across all business operations.",
      },
      help2: {
        heading: "Risk Mitigation Strategies",
        des: "EazyComply implements strategies to reduce or eliminate risks using data-driven insights from the platform.",
      },
      help3: {
        heading: "Continuous Monitoring",
        des: "EazyComply keeps track of risk factors in real-time to ensure ongoing risk management and compliance.",
      },
      help4: {
        heading: "Reporting and Analytics",
        des: "EazyComply generates detailed reports to communicate risk management efforts to stakeholders.",
      },
    },
  },
  {
    title: "Digital Personal Data Protection",
    id: "data-privacy-protection",
    center: false,
    des: "Digital Personal Data Protection ensures businesses remain compliant with the DPDP Act, helping them understand mandates, minimize risks, and implement robust data protection.",
    img: "DPDP.png",
    answer:
      "Digital Personal Data Protection refers to the processes and regulations designed to safeguard personal information in digital formats. This includes protecting against unauthorized access, breaches, and misuse of personal data. Key elements include encryption, data anonymization, and compliance with legal frameworks such as GDPR, CCPA, and the DPDP Act, ensuring individuals' privacy rights are respected in digital environments.",
    risk: {
      risk1: {
        heading: "Legal and Regulatory Risks",
        des: "Companies may face fines, penalties, and legal actions due to non-compliance with data protection laws.",
      },
      risk2: {
        heading: "Reputation Risks",
        des: "Poor data protection practices can harm a company's brand and reduce customer trust.",
      },
      risk3: {
        heading: "Financial Risks",
        des: "Non-compliance can lead to fines and litigation, as well as reputation damage and decreased customer loyalty",
      },
      risk4: {
        heading: "Operational Risks",
        des: "Failure to address data protection issues can lead to disruptions, such as security breaches or data loss.",
      },
    },
    help: {
      help1: {
        heading: "Comprehensive Compliance Assessment",
        des: "EazyComply evaluates current data management practices to identify gaps and areas of non-compliance with the DPDP Act.",
      },
      help2: {
        heading: "Customized Compliance Strategy",
        des: "EazyComply develops tailored strategies to align business operations with DPDP Act requirements, ensuring a smooth transition to compliance.",
      },
      help3: {
        heading: "Training and Awareness Programs",
        des: "EazyComply provides specialized training programs to equip teams with knowledge and skills to manage personal data in compliance with the Act.",
      },
      help4: {
        heading: "Ongoing Support and Monitoring",
        des: "EazyComply offers continuous support to maintain compliance through regular updates on regulatory changes and audit assistance.",
      },
    },
  },
  {
    title: "Environmental, Social, and Governance",
    back: "ESG.jpg",
    center: true,
    id: "environmental-social-governance",
    des: "Environmental, Social, and Governance (ESG) measures the sustainability and societal impact of a company or business investment. It is an increasingly important factor for investors.",
    img: "ESG.png",
    answer:
      "ESG criteria are a set of standards for a company’s operations that socially conscious investors use to screen potential investments. Environmental criteria consider how a company performs as a steward of nature. Social criteria examine relationships with employees, suppliers, and communities. Governance deals with leadership, executive pay, audits, and shareholder rights.",
    risk: {
      risk1: {
        heading: "Legal and Regulatory Risks",
        des: "Companies may face fines, penalties, and legal action due to poor ESG practices.",
      },
      risk2: {
        heading: "Reputation Risks",
        des: "Poor ESG practices can lead to negative publicity and loss of business trust.",
      },
      risk3: {
        heading: "Financial Risks",
        des: "Non-compliance with ESG standards can result in fines, litigation, and a damaged reputation.",
      },
      risk4: {
        heading: "Operational Risks",
        des: "ESG issues can lead to environmental incidents or labor disputes, disrupting operations.",
      },
    },
    help: {
      help1: {
        heading: "Integrated ESG Management Platform",
        des: "EazyComply monitors and manages ESG performance across all business operations, tracking energy usage, waste management, and social initiatives.",
      },
      help2: {
        heading: "Regulatory Updates and Alerts",
        des: "EazyComply keeps businesses up-to-date with ESG regulations and standards, providing real-time alerts for compliance.",
      },
      help3: {
        heading: "Risk Assessment and Mitigation",
        des: "EazyComply conducts thorough risk assessments and provides actionable insights to address ESG risks proactively.",
      },
      help4: {
        heading: "Reporting and Transparency",
        des: "EazyComply generates detailed ESG reports to showcase compliance efforts to stakeholders.",
      },
    },
  },
  {
    title: "Governance, Risk, and Compliance",
    back: "GRC.jpg",
    center: true,
    id: "governance-risk-compliance",
    des: "Governance, Risk, and Compliance (GRC) is an integrated approach that ensures an organization achieves its objectives reliably, addresses uncertainty, and acts with integrity.",
    img: "GRC.webp",
    answer:
      "GRC integrates governance structures, risk management, and compliance with regulations to ensure an organization adheres to legal, ethical, and operational standards while meeting its objectives",
    risk: {
      risk1: {
        heading: "Legal and Regulatory Risks",
        des: "Non-compliance with laws and regulations can lead to penalties and sanctions.",
      },
      risk2: {
        heading: "Reputation Risks",
        des: "Failure to manage risks and compliance can damage a business’s reputation and stakeholder trust.",
      },
      risk3: {
        heading: "Financial Risks",
        des: "Financial losses can arise from fines, litigation, and inefficiencies in governance and compliance.",
      },
      risk4: {
        heading: "Operational Risks",
        des: "Inefficiencies or disruptions due to poor governance and risk management.",
      },
    },
    help: {
      help1: {
        heading: "Policy Management",
        des: "EazyComply helps businesses develop and manage policies to ensure regulatory compliance.",
      },
      help2: {
        heading: "Risk Management",
        des: "EazyComply identifies and mitigates risks using an integrated platform.",
      },
      help3: {
        heading: "Compliance Monitoring",
        des: "EazyComply tracks compliance status and sends alerts for any deviations.",
      },
      help4: {
        heading: "Audit and Reporting",
        des: "EazyComply generates comprehensive audit reports to demonstrate compliance efforts.",
      },
    },
  },
  {
    title: "Workplace Health and Safety",
    back: "WHS.jpg",
    center: false,
    id: "workplace-health-safety",
    des: "Work Health and Safety (WHS) focuses on protecting the health, safety, and welfare of employees in the workplace.",
    answer:
      "WHS encompasses policies, procedures, and practices to ensure employee health and safety. It includes hazard identification, risk assessments, and the implementation of safety measures to prevent injuries, illnesses, and fatalities. WHS also involves employee training, emergency preparedness, and continuous safety monitoring.",
    risk: {
      risk1: {
        heading: "Legal and Regulatory Risks",
        des: "Failure to comply with WHS regulations can lead to fines, penalties, and legal actions.",
      },
      risk2: {
        heading: "Reputation Risks",
        des: "Poor WHS practices can damage business reputation and trust.",
      },
      risk3: {
        heading: "Financial Risks",
        des: "Non-compliance can result in fines and litigation, along with reduced customer loyalty due to reputational damage.",
      },
      risk4: {
        heading: "Operational Risks",
        des: "Disruptions due to safety incidents can impact business continuity.",
      },
    },
    help: {
      help1: {
        heading: "Risk Assessments",
        des: "EazyComply identifies and assesses workplace hazards through its platform.",
      },
      help2: {
        heading: "Safety Protocols",
        des: "EazyComply implements safety procedures to mitigate risks and improve workplace safety.",
      },
      help3: {
        heading: "Training and Awareness",
        des: "EazyComply provides employee training to ensure adherence to WHS standards.",
      },
      help4: {
        heading: "Monitoring and Reporting",
        des: "EazyComply tracks WHS compliance and generates reports to demonstrate safety efforts.",
      },
    },
  },
  {
    title: "Security and Loss Prevention",
    back: "SLP.jpg",
    center: false,
    id: "security-loss-prevention",
    des: "Security and Loss Prevention (SLP) focuses on protecting an organization’s assets from theft, fraud, and other security threats.",
    img: "SLP.jpg",
    answer:
      "SLP involves strategies aimed at safeguarding physical and intellectual property and personnel. It includes preventing theft, fraud, vandalism, and other losses through surveillance, cybersecurity, and employee training.",
    risk: {
      risk1: {
        heading: "Reputation Risks",
        des: "Security breaches can harm a business’s reputation and stakeholder trust.",
      },
      risk2: {
        heading: "Financial Risks",
        des: "Financial losses due to theft, fraud, or security breaches.",
      },
      risk3: {
        heading: "Operational Risks",
        des: "Security incidents can disrupt operations and harm productivity.",
      },
    },
    help: {
      help1: {
        heading: "Security Audits",
        des: "EazyComply conducts audits to identify security vulnerabilities and improve protection measures.",
      },
      help2: {
        heading: "Loss Prevention Strategies",
        des: "EazyComply implements strategies to prevent theft and fraud across business operations.",
      },
      help3: {
        heading: "Incident Response",
        des: "EazyComply develops and executes comprehensive response plans for security incidents.",
      },
    },
  },
];

export const stageService = [
  {
    title: "GRC Compliance Audit",
    linkTo: "stage",
    id: "grc-compliance-audit",
    des: "EazyComply's GRC Compliance Audit helps businesses maintain a strong governance, risk management, and compliance framework. By identifying gaps and ensuring regulatory adherence, EazyComply enable businesses to mitigate risks, improve governance practices, and meet compliance obligations seamlessly.",
    img: "GRC.avif",
    back: "Grc_Main.jpg",
    answer:
      "A GRC Compliance Audit is a comprehensive review of an organization's governance, risk, and compliance mechanisms. This audit evaluates how effectively a company manages its governance policies, risk controls, and regulatory compliance. It examines internal controls, business policies, and compliance processes to ensure they are aligned with industry regulations and best practices. This audit not only identifies gaps but also provides actionable insights to enhance your company's resilience, ensuring both compliance and operational efficiency.",
    risk: {
      risk1: {
        heading: "Compliance Gaps",
        des: "Failure to identify and address compliance gaps can result in legal penalties, operational inefficiencies, and reputational damage.",
      },
      risk2: {
        heading: "Ineffective Governance",
        des: "Poor governance can lead to inconsistent decision-making and weak oversight, exposing businesses to strategic risks.",
      },
      risk3: {
        heading: "Unmanaged Risks",
        des: "Inadequate risk management may result in unforeseen financial losses or operational disruptions.",
      },
      risk4: {
        heading: "Obsolete Compliance Processes",
        des: "Failing to keep compliance processes updated with regulatory changes can result in penalties and reputational harm.",
      },
    },
    help: {
      help1: {
        heading: "Comprehensive Audit",
        des: "EazyComply provides a detailed audit of your compliance framework, offering corrective actions to ensure regulatory alignment and minimize risks.",
      },
      help2: {
        heading: "Governance Assessment",
        des: "The audit assesses governance structures, improving transparency, accountability, and decision-making to strengthen organizational resilience.",
      },
      help3: {
        heading: "Risk Management Evaluation",
        des: "EazyComply evaluates risk management processes, helping businesses to proactively mitigate potential threats and improve operational stability.",
      },
      help4: {
        heading: "Regulatory Updates",
        des: "EazyComply provides real-time regulatory updates and automated solutions to keep compliance efforts current and efficient.",
      },
    },
  },
  {
    title: "Start-Up Audit",
    linkTo: "stage",
    id: "start-up-audit",
    des: "EazyComply's Start-Up Audit ensures that newly established businesses lay a strong foundation for compliance and operational efficiency. By identifying potential risks early, EazyComply help start-ups build resilient processes that support growth and investor confidence.",
    img: "SU.jpg",
    back: "SU.jpg",
    center: true,
    answer:
      "A Start-Up Audit is designed to evaluate the compliance and operational readiness of newly established businesses. This audit looks at key areas such as regulatory compliance, financial practices, and operational workflows to identify risks and areas of improvement. By doing so, it helps start-ups build a scalable business model that adheres to industry standards and regulatory requirements. This audit is crucial for securing investments and fostering business growth while minimizing compliance risks.",
    risk: {
      risk1: {
        heading: "Lack of Compliance Structure",
        des: "Start-ups without a formal compliance structure may face legal risks and challenges in securing investment.",
      },
      risk2: {
        heading: "Operational Inefficiencies",
        des: "Inefficient operations can limit scalability and slow business growth.",
      },
      risk3: {
        heading: "Investor Concerns",
        des: "Non-compliance or operational weaknesses can deter potential investors.",
      },
      risk4: {
        heading: "Regulatory Blind Spots",
        des: "Newly established businesses may be unaware of critical regulations, leading to potential penalties.",
      },
    },
    help: {
      help1: {
        heading: "Compliance Framework",
        des: "EazyComply helps establish a tailored compliance framework, ensuring the business meets regulatory expectations from the outset.",
      },
      help2: {
        heading: "Operational Optimization",
        des: "EazyComply assesses and optimizes operational processes to enhance efficiency and support long-term growth.",
      },
      help3: {
        heading: "Investor Confidence",
        des: "The audit ensures compliance and operational soundness, instilling confidence in investors and stakeholders.",
      },
      help4: {
        heading: "Regulatory Insights",
        des: "EazyComply provides real-time regulatory insights, ensuring the start-up remains compliant with evolving industry standards.",
      },
    },
  },
  {
    title: "Due-Diligence Audit",
    linkTo: "stage",
    id: "due-diligence-audit",
    des: "EazyComply’s Due-Diligence Audit helps businesses prepare for acquisitions, mergers, or investments by providing a comprehensive assessment of their legal, financial, and compliance standings. This ensures transparency and reduces risks during corporate transactions",
    img: "due.avif",
    back: "due.jpg",
    center: true,
    answer:
      "A Due-Diligence Audit is an in-depth review conducted before a merger, acquisition, or significant investment. It evaluates the target company’s financial, legal, and compliance health  to ensure that the business transaction is sound and beneficial. This audit helps both buyers and sellers gain a clear understanding of potential liabilities, operational challenges, and compliance risks, providing the insights necessary for informed decision-making. It is a critical step in reducing post-transaction surprises and ensuring a smooth integration.",
    risk: {
      risk1: {
        heading: "Hidden Liabilities",
        des: "Unidentified legal or financial liabilities can derail transactions or cause post-acquisition challenges.",
      },
      risk2: {
        heading: "Regulatory Non-Compliance",
        des: "Failure to meet regulatory requirements can result in transaction delays or legal consequences.",
      },
      risk3: {
        heading: "Inaccurate Financial Reporting",
        des: "Inaccurate or incomplete financial reports can lead to poor transaction outcomes and increased risks for investors.",
      },
      risk4: {
        heading: "Unclear Contractual Obligations",
        des: "Unclear or unfavorable contracts can lead to disputes post-transaction.",
      },
    },
    help: {
      help1: {
        heading: "Liability Identification",
        des: "EazyComply conducts thorough reviews to identify hidden liabilities and provide recommendations to mitigate risks.",
      },
      help2: {
        heading: "Regulatory Compliance",
        des: "EazyComply ensures compliance across all relevant regulations, reducing the risk of legal complications during mergers or acquisitions.",
      },
      help3: {
        heading: "Financial Validation",
        des: "The audit validates financial reports and ensures that they meet industry standards, providing clarity for stakeholders.",
      },
      help4: {
        heading: "Contract Review",
        des: "EazyComply reviews contracts thoroughly, identifying potential risks and ensuring that obligations are transparent and manageable.",
      },
    },
  },
  {
    title: "IPO Audit",
    linkTo: "stage",
    id: "ipo-audit",
    des: "EazyComply’s IPO Audit prepares businesses for the stringent requirements of going public. EazyComply ensures compliance with regulatory standards, reviews financial records, and helps build investor confidence by providing transparency in operations.",
    img: "IPO.jpg",
    back: "IPO.jpg",
    answer:
      "An IPO Audit is a specialized audit conducted when a company is preparing to go public. This audit ensures that the company meets all regulatory and financial reporting requirements, providing transparency to potential investors and regulatory bodies. It includes an assessment of governance structures, financial disclosures, and operational processes to ensure the business is fully compliant and ready for the rigorous demands of the public markets. This audit plays a key role in mitigating risks during the IPO process and enhancing investor confidence.",
    risk: {
      risk1: {
        heading: "Non-Compliance with IPO Regulations",
        des: "Failure to comply with IPO regulations can result in delays, penalties or loss of investor trust.",
      },
      risk2: {
        heading: "Inaccurate Financial Disclosures",
        des: "Inaccurate or incomplete financial disclosures can damage investor confidence and lead to legal consequences.",
      },
      risk3: {
        heading: "Weak Governance Structures",
        des: "Inadequate governance can create operational risks and limit the success of an IPO.",
      },
      risk4: {
        heading: "Operational Risks",
        des: "Unmanaged operational risks can negatively impact stock performance post-IPO.",
      },
    },
    help: {
      help1: {
        heading: "Regulatory Compliance",
        des: "EazyComply reviews compliance with all relevant regulations, ensuring a smooth and compliant IPO process.",
      },
      help2: {
        heading: "Financial Reporting",
        des: "The audit ensures accurate financial reporting, building trust with potential investors and regulatory bodies.",
      },
      help3: {
        heading: "Governance Enhancement",
        des: "EazyComply assesses and enhances governance practices, ensuring strong leadership and decision-making frameworks for public operations.",
      },
      help4: {
        heading: "Risk Mitigation",
        des: "EazyComply evaluates operational processes to identify and mitigate risks, ensuring a stable and efficient post-IPO environment.",
      },
    },
  },
  {
    title: "Off-Boarding Audit",
    linkTo: "stage",
    id: "off-boarding-audit",
    des: "EazyComply’s Off-Boarding Audit ensures that the termination of contracts, employees, or vendors is managed smoothly and in compliance with legal obligations, reducing risks during transitions.",
    img: "OFF.avif",
    back: "OFF.jpg",
    answer:
      "An Off-Boarding Audit is an essential process for companies terminating relationships with employees, vendors, or clients. This audit ensures that all legal, contractual, and regulatory obligations are met, reducing the risk of disputes and ensuring a smooth transition. It also ensures that sensitive information is handled properly during off-boarding, minimizing the risk of data breaches or compliance issues. This audit helps businesses navigate exits without legal or operational setbacks.",
    risk: {
      risk1: {
        heading: "Data Breaches",
        des: "Improper data handling during off-boarding can result in security breaches or legal repercussions.",
      },
      risk2: {
        heading: "Contractual Disputes",
        des: "Failure to meet contractual obligations during off-boarding can lead to disputes or legal action.",
      },
      risk3: {
        heading: "Loss of Institutional Knowledge",
        des: "Off-boarding key personnel without proper knowledge transfer can disrupt operations.",
      },
      risk4: {
        heading: "Legal Compliance Issues",
        des: "Non-compliance with labor laws or contractual terms during off-boarding can lead to penalties.",
      },
    },
    help: {
      help1: {
        heading: "Secure Data Management",
        des: "EazyComply ensures secure data management and compliance with privacy regulations during off-boarding processes.",
      },
      help2: {
        heading: "Contract Review",
        des: "EazyComply reviews contracts and ensure that all obligations are met, minimizing the risk of disputes.",
      },
      help3: {
        heading: "Knowledge Transfer",
        des: "EazyComply helps establish knowledge transfer protocols, ensuring operational continuity during personnel transitions.",
      },
      help4: {
        heading: "Legal Compliance",
        des: "EazyComply ensures that all legal requirements are met, reducing the risk of penalties or legal complications.",
      },
    },
  },
  {
    title: "M&A Audit",
    linkTo: "stage",
    id: "m-and-a-audit",
    des: "EazyComply’s M&A Audit provides a comprehensive evaluation of both parties involved in mergers or acquisitions. By identifying potential risks, assessing regulatory compliance, and analyzing operational and financial health, EazyComply ensures a smooth and informed transition during these critical business transactions.",
    img: "MA.jpg",
    back: "MA.avif",
    center: true,
    answer:
      "An M&A Audit is a critical assessment performed when businesses are involved in mergers or acquisitions. This audit evaluates the financial, legal, and operational health of the organizations, helping identify potential risks and liabilities before the transaction. By examining governance structures, compliance records, and contractual obligations, the M&A Audit ensures that both parties are aligned and prepared for a successful transition, minimizing post-transaction surprises.",
    risk: {
      risk1: {
        heading: "Unidentified Liabilities",
        des: "Hidden financial or legal liabilities can disrupt the transaction and lead to significant financial losses post-merger.",
      },
      risk2: {
        heading: "Regulatory Non-Compliance",
        des: "Non-compliance with local or international regulations can delay the merger or result in legal consequences for both parties.",
      },
      risk3: {
        heading: "Inconsistent Governance",
        des: "Differing governance structures between merging entities can create operational inefficiencies and decision-making conflicts.",
      },
      risk4: {
        heading: "Inaccurate Financial Reporting",
        des: "Incomplete or inaccurate financial records can lead to misinformed decisions, negatively impacting the transaction's value.",
      },
    },
    help: {
      help1: {
        heading: "Liability Review",
        des: "EazyComply conducts in-depth reviews to uncover potential liabilities, ensuring transparency and minimizing post-merger risks.",
      },
      help2: {
        heading: "Regulatory Compliance",
        des: "EazyComply audit ensures all parties comply with relevant regulations, providing peace of mind and reducing the likelihood of legal hurdles.",
      },
      help3: {
        heading: "Governance Alignment",
        des: "EazyComply evaluates and aligns governance frameworks to ensure smooth integration and operational efficiency post-merger.",
      },
      help4: {
        heading: "Financial Validation",
        des: "EazyComply reviews and validates financial data, ensuring accurate reporting and protecting stakeholders from potential financial risks.",
      },
    },
  },
  {
    title: "FDI in India (Business Initiation Audit)",
    linkTo: "stage",
    id: "fdi-india-audit",
    des: "EazyComply’s FDI in India Audit helps foreign businesses navigate the complex regulatory landscape of establishing operations in India. The audit ensures compliance with FDI regulations, financial reporting, and local laws, providing foreign investors with the confidence to set up operations seamlessly and securely.",
    img: "FDI.jpg",
    back: "FDI.jpg",
    center: true,
    answer:
      "An FDI in India (Business Initiation) Audit is a thorough assessment of compliance requirements and operational readiness for foreign companies looking to establish a presence in India. This audit covers all aspects of regulatory compliance, from FDI laws and foreign exchange regulations to industry-specific requirements. By ensuring that businesses are compliant with local laws, the audit helps foreign investors mitigate risks, avoid legal pitfalls, and efficiently set up their operations in India.",
    risk: {
      risk1: {
        heading: "Non-Compliance with FDI Regulations",
        des: "Failing to comply with India’s Foreign Direct Investment (FDI) regulations can result in fines, legal issues, and delays in business setup.",
      },
      risk2: {
        heading: "Complex Regulatory Environment",
        des: "Navigating the complex legal and regulatory landscape in India can be challenging for foreign entities, leading to delays and potential penalties.",
      },
      risk3: {
        heading: "Financial and Tax Compliance",
        des: "Non-compliance with financial and tax regulations can lead to audits, penalties, and reputational damage.",
      },
      risk4: {
        heading: "Operational Readiness",
        des: "Inadequate operational setup may delay business activities and lead to inefficiencies.",
      },
    },
    help: {
      help1: {
        heading: "FDI Compliance",
        des: "EazyComply ensures that the business adheres to all FDI rules and guidelines, providing the legal framework for a smooth market entry.",
      },
      help2: {
        heading: "Regulatory Guidance",
        des: "EazyComply simplifies the regulatory process by offering expert guidance and real-time updates on laws, ensuring that the business complies with all relevant statutes.",
      },
      help3: {
        heading: "Financial Framework",
        des: "EazyComply helps establish a compliant financial framework in line with Indian tax laws, ensuring smooth financial operations from day one.",
      },
      help4: {
        heading: "Operational Assessment",
        des: "EazyComply evaluates operational processes, ensuring that the business infrastructure is prepared for efficient and compliant operations in the Indian market.",
      },
    },
  },
];

export const why = [
  {
    icon: <AI size={24} color={"#fff"} />,
    title: "Manufacturing firm based in Chennai",
    description:
      "Eazycomply has revolutionized the way EazyComply manage EazyComply compliance and risk assessments. EazyComply platform is intuitive and has significantly streamlined our processes, ensuring that EazyComply stay ahead of regulatory changes.",
  },
  {
    icon: <Book size={24} color={"#fff"} />,
    title: "Financial services company in Mumbai",
    description:
      "The integration of Eazycomply's solutions into our daily operations has been seamless. EazyComply proactive approach to risk management has not only mitigated potential issues but also provided us with valuable insights to improve our overall governance.",
  },
  {
    icon: <Slider size={24} color={"#fff"} />,
    title: "Retail chain headquartered in Bengaluru",
    description:
      "Eazycomply's risk management tools have been a game-changer for our business. EazyComply platform is user-friendly, and the support team is always ready to help, making compliance much easier to handle.",
  },
  {
    icon: <Analytics size={24} color={"#fff"} />,
    title: "Energy company operating across Delhi-NCR",
    description:
      "With Eazycomply, we have enhanced our ESG compliance and built a stronger, more resilient business. The platform's comprehensive reporting features have been particularly useful for our stakeholder communications.",
  },
];

export const platformFeatures = [
  {
    title: "Centralized controls",
    des: "Allows client company to have an overview of the complete list of controls relevant to the organization so only the right people have access to the information.",
    icon: <Slider size={24} color={"#fff"} />,
  },
  {
    title: "Agile system",
    des: "Easy adaptability to support existing and future standards based on changes in the ecosystem, without hindering existing processes",
    icon: <Monitor size={24} color={"#fff"} />,
  },
  {
    title: "Automation-centric",
    des: "Can automate workflows/reporting to eliminate human error, improve quality and efficiency.",
    icon: <AI size={24} color={"#fff"} />,
  },
  {
    title: "Scalability",
    des: "As per client requirements and competitor offerings in the market, integrating additional tools (ex: Jira/Slack) and having an Application Programming Interface (API) for integration with third-party tools.",
    icon: <Design size={24} color={"#fff"} />,
  },
  {
    title: "Customizable reporting",
    des: "Can support the creation of customizable, in-depth reports that suit clients’ needs and support better decision-making.",
    icon: <Puzzle size={24} color={"#fff"} />,
  },
  {
    title: "Manage task delegation",
    des: "Support task delegation across client’s organization with minimum support intervention.",
    icon: <Analytics size={24} color={"#fff"} />,
  },
];

export const Techs = [
  {
    head: "Cloud Infrastructure",
    des: "Highly Scalable, Secure & Elastic System. No infra is required.",
    icon: <Upload size={40} color={"#1f1f1f"} />,
  },
  {
    head: "Role-Based Access Control",
    des: "Fine Grained RBAC to maintain the confidentiality and integrity of sensitive information.",
    icon: <Lock size={40} color={"#1f1f1f"} />,
  },
  {
    head: "Audit Trails and Logging",
    des: "Maintain & monitor comprehensive audit trails and logs to track user activities and system events. ",
    icon: <Doc size={40} color={"#1f1f1f"} />,
  },
  {
    head: "AI-Based Compliance Monitoring",
    des: "To continuously assess and report on adherence to regulatory requirements.",
    icon: <Shield size={40} color={"#1f1f1f"} />,
  },
  {
    head: "Regular Risk Audits and Risk Assessments",
    des: "To identify and address potential vulnerabilities.",
    icon: <CheckList size={40} color={"#1f1f1f"} />,
  },
  {
    head: "Regulatory Updates and Adaptability",
    des: "To stay informed about changes in relevant regulations and update the system accordingly.",
    icon: <Refresh size={40} color={"#1f1f1f"} />,
  },
  {
    head: "Vendor Risk Management",
    des: "Tools to assess and monitor the risks associated with third-party vendors and partners. Regularly evaluate and update the risk profile of vendors based on changing circumstances",
    icon: <HandShake size={40} color={"#1f1f1f"} />,
  },
];

export const Members = [
  {
    name: "Col. Rohit Gogna",
    link: "https://www.linkedin.com/in/rohit-gogna-0993773a/",
    role: "Founder",
    img: "/assets/member/Rohit_Gogna.jpeg",
  },
  {
    name: "Adv. Somya Singh",
    link: "https://www.linkedin.com/in/somya-singh-a4074759/",
    role: "Co Founder",
    img: "/assets/member/Somya_singh.jpeg",
  },
  {
    name: "Anuj Jain",
    link: "https://www.linkedin.com/in/99oranges/",
    role: "Co Founder",
    img: "/assets/member/Anuj_Jain.jpeg",
  },
];

export const ServicePageData = [
  {
    id: "legal-updates",
    name: "Legal Update Service",
    short_name: "Legal Update",
    description:
      "Delivers timely updates on pertinent legislation, regulations, and compliance requirements to organizations.",
    img: "/assets/servicePage/legal.avif",
    answer:
      "Facilitates organizational awareness of regulatory changes, enabling proactive adaptation and sustained compliance. Provides critical information to mitigate non-compliance risks and ensure operational alignment with legal standards.",
    icon: "legal-icon.svg",
    how: "Disseminates information on regulatory changes, fostering rapid adaptation and ongoing compliance. Mitigates non-compliance risks and maintains operational alignment with legal standards. Enables executive leadership to make informed strategic decisions, supporting core operational growth.",
    benefits: [
      "Scheduled alerts on legislative changes and compliance requirements, tailored to specific industries and jurisdictions.",
      "Prompt updates on legal and regulatory amendments customized to individual business requirements.",
      "Professional analysis and impact assessments for significant legal updates affecting core business operations.",
    ],
  },
  {
    id: "compliance-audit",
    name: "Compliance Audit Service",
    short_name: "Compliance Audit",
    description:
      "Conducts comprehensive reviews of organizational adherence to regulatory requirements and internal policies.",
    img: "/assets/servicePage/audit.jpg",
    answer:
      "Identifies compliance discrepancies, evaluates potential risks, and furnishes actionable recommendations. Facilitates maintenance of regulatory standards and enhancement of governance, ensuring all operations adhere to legal and industry requirements.",
    icon: "audit-icon.svg",
    how: "Detects non-compliances and provides actionable recommendations for each instance. Supports organizations in maintaining regulatory obligations and enhancing governance, ensuring all processes adhere to statutory requirements and industry standards.",
    benefits: [
      "Comprehensive compliance framework customized to specific business requirements.",
      "Point-in-Time or Continuous Assessments to establish templates based on relevant business factors, identify gaps, and propose areas for enhancement. Each assessment generates a Compliance Scorecard quantifying non-compliance and delineating a Path to Green.",
      "Monitoring and tracking mechanisms for statutory and regulatory compliance requirements in both Point-in-Time and Continuous Compliance Audit Monitoring Services.",
      "Detailed audit reports and scorecards with actionable insights through structured remediation plans.",
    ],
  },
  {
    id: "risk-assessment",
    name: "Risk Assessment Service",
    short_name: "Risk Assessment",
    description:
      "Executes identification, analysis, and evaluation of potential risks impacting organizational operations.",
    img: "/assets/servicePage/risk.jpg",
    answer:
      "Performs comprehensive risk assessments to enable businesses to anticipate potential issues and implement risk mitigation strategies. Enhances decision-making processes and minimizes the probability of unforeseen disruptions or losses.",
    icon: "risk-icon.svg",
    how: "Fosters a risk-based mindset and identifies potential challenges that may pose operational risks. Ensures preemptive risk identification, thereby enhancing overall decision-making processes.",
    benefits: [
      "Comprehensive Risk Assessment across multiple domains including operations, finance, environment, legal, strategic, and reputational risks.",
      "Customized and Calibrated Risk Matrix engineered to address unique business challenges.",
      "FMEA framework based on exhaustive analysis of the Risk Environment and risk outliers within the Risk Universe.",
      "Risk Matrix evaluations with executive leadership to refine and update Risk Management strategies.",
    ],
  },
  {
    id: "control-effectiveness",
    name: "Design Control Effectiveness Service",
    short_name: "Design Control Effectiveness",
    description:
      "Evaluates the efficacy of controls and processes implemented to mitigate risks and ensure compliance.",
    img: "/assets/servicePage/design.jpg",
    answer:
      "Assesses the appropriate design and intended functionality of risk controls. Augments an organization's capacity to manage risks effectively and ensures compliance measures remain robust and adaptive to evolving challenges.",
    icon: "control-icon.svg",
    how: "Evaluates risk controls for design integrity and functional efficacy. Enhances an organization's capacity to manage risks effectively and ensures risk mitigation measures remain robust and adaptive to evolving challenges.",
    benefits: [
      "Meticulous formulation of risk control frameworks and procedures.",
      "Validation of existing controls to quantify efficacy metrics.",
      "Optimization services to enhance risk control effectiveness.",
      "Continuous improvement strategies to adapt to emerging risks and evolving business risk landscapes.",
    ],
  },
  {
    id: "scope",
    name: "SCOPE Service (Site Compliances and Operational Processes Evaluation)",
    short_name: "SCOPE",
    description:
      "Conducts on-site evaluation of physical premises, encompassing comprehensive assessment of operational risks and compliance status.",
    img: "/assets/servicePage/scope.jpg",
    answer:
      "Executes on-site evaluations for physical premises. Encompasses comprehensive assessment of operational risks and compliance status.",
    icon: "oree-icon.svg",
    how: "Engages subject matter experts to conduct thorough on-site inspections, evaluating operational processes and risk exposure to identify compliance gaps. Generates comprehensive reports with actionable insights, ensuring stringent Risk Management standards. Optimizes on-site operations through direct identification of vulnerabilities, supporting business growth via enhanced process efficiency while maintaining continuous compliance.",
    benefits: [
      "In-depth assessments of statutory and regulatory compliance at business locations. Identification of non-compliance issues.",
      "Evaluation of operational workflows and processes to isolate potential risks. Comprehensive analysis of key operational areas including safety procedures, security measures, and workflow efficiency.",
      "Detailed reports highlighting site-specific risks, compliance gaps, and operational inefficiencies. Actionable insights to facilitate management of identified risks.",
      "Guidance in developing and implementing controls to address identified risks.",
      "Strategic recommendations for enhancing site operations and optimizing compliance procedures. Expert guidance on integrating industry best practices to elevate overall operational effectiveness.",
    ],
  },
];

export const Pricing = [
  {
    service_name: "Legal Update Service",
    short_name: "Legal Update",
    tag_line:
      "Real-time, Pan-India legal update newsletter & service personalized for your business",
    suggested_tag_line: "Stay Ahead of Regulations, Stay Ahead of Risks",
    service_editions: [
      "Self Service Legal Update Service",
      "Enterprise Legal Update Service",
    ],
    service_charges: {
      monthly: "₹5000",
      annually: "₹3000",
    },
    key_features: [
      "View the list of all the legal updates based on your industry and location",
      "Capability to view the details of any legal update",
      "Capability to view the associate Act and various compliances under the act",
      "Configure recipient emails and schedule of the update",
    ],
    more_features: [
      "Access to Webinar on latest compliance updates",
      "Access to legal officer to understand the implication of any legal updates on your business",
    ],
  },
  {
    service_name: "Compliance Audit Service",
    short_name: "Compliance Audit",
    tag_line:
      "Best in class compliance framework to track & monitor compliances for your business",
    suggested_tag_line: "Uncover Gaps, Ensure Compliance, Drive Excellence",
    service_editions: [
      "Self Service Compliance Audit Solution",
      "Enterprise Compliance Audit Solution",
    ],
    service_charges: {
      monthly: "Contact for Pricing",
      annually: "Contact for Pricing",
    },

    key_features: [
      "End to End Compliance Audit Workflow",
      "Audit questionnaire based on latest legal requirements",
      "Supports audit of third party as well as internal departments",
      "Comprehensive corrective action plan workflow",
    ],
    more_features: [
      "Hand holding & setup based on your specific needs",
      "Risk experts to help you define your compliance audit requirements",
      "Compliance audit team to perform end-to-end audit and submit audit report & score",
      "Supports in resolving non-compliance at any level",
      "Option of Continuous Assessment Program for automated re-audit on regular basis to improve compliance score",
      "Foolproof Risk Management by mitigation and transfer strategies",
      "Establish a virtuous cycle of improvement : Risk Assessment - Compliance Audit - Control Effectiveness",
      "Specific dashboard built for Management, Auditors, & Auditees",
      "Cloud-hosted, secure environment with Role-based access controls",
      "Real-time comprehensive audit report generation",
    ],
  },
  {
    service_name: "Risk Assessment Service",
    short_name: "Risk Assessment",
    tag_line:
      "Comprehensive risk evaluation and mitigation strategies to secure your business from unforeseen threats.",
    suggested_tag_line: "Anticipate Risks, Safeguard Your Future",
    service_editions: ["Enterprise Risk Assessment Solution"],

    service_charges: {
      monthly: "Contact for Pricing",
      annually: "Contact for Pricing",
    },

    key_features: [
      "Exhaustive assessment of the Risk Environment to identify potential risks",
      "Risk weighage to each risk in the risk register",
      "Wide swath to identify high impact, low occurrence risks",
      "Operational, environmental, litigation, financial, safety, political, security, reputation risks etc",
    ],
    more_features: [
      "Setup based on your specific risk environment",
      "Risk Assessment experts to help you define your risk appetite",
      "Subject Matter Experts to develop a risk mindset",
      "Support for understanding the risk universe for broader identification",
      "Establish a virtuous cycle of improvement : Risk Assessment - Compliance Audit - Control Effectiveness",
      "Independent module for Risk Assessment",
      "Cloud hosted, secure environment with Role Based Access Controls",
      "Identify short, medium and long term risks with quantified damage potential",
    ],
  },
  {
    service_name: "Control Effectiveness Service",
    short_name: "Control Effectiveness",
    tag_line:
      "Optimize and enhance your risk controls with cutting-edge assessments for robust compliance management.",
    suggested_tag_line: "Strengthening Controls, Securing Success",
    service_editions: ["Enterprise Control Effectiveness Solution"],

    service_charges: {
      monthly: "Contact for Pricing",
      annually: "Contact for Pricing",
    },

    key_features: [
      "Integrated Design Control Effectiveness Check",
      "AI based evaluation of design",
      "Run-to-Failure Testing",
      "Assignment of quantified rating to each Control",
    ],
    more_features: [
      "Access to Webinar on latest compliance updates",
      "Access to legal officer to understand the implication of any legal updates on your business",
      "Systematic reviews based on FMEA and time since inception",
      "Risk environment evolution based efficacy testing",
    ],
  },
  {
    service_name:
      "Site Compliances and Operational Processes Evaluation Service",
    short_name: "SCOPE",
    tag_line:
      "Real-time, on-site risk evaluations to safeguard your business operations.",
    suggested_tag_line: "On-Site, On-Point Compliance",
    service_editions: ["Enterprise SCOPE Service"],

    service_charges: {
      monthly: "Contact for Pricing",
      annually: "Contact for Pricing",
    },

    key_features: [
      "Comprehensive On-Site Compliances Evaluation",
      "Detailed Process Mapping to arrive at compliance gaps",
      "Supports audit of third party (Vendors) as well as internal departments",
      "Comprehensive corrective action plan workflow to help business improve its compliance scorecard",
    ],
    more_features: [
      "Hand holding & setup based on your specific needs",
      "Risk experts to help you define your site compliance requirements",
      "Physical compliance audit professionals to perform end to end site audit and submit report & score",
      "Supports in resolving non-compliance at any level",
      "Establish a virtuous cycle of improvement : Risk Assessment - Site Compliance Audit - Control Effectiveness",
      "Operations robustness check to manage freak event occurrences",
      "Inter-Department dependency and vulnerability assessment",
      "Real time, Comprehensive Audit Report generation",
    ],
  },
];
