import React from "react";
import { Link } from "react-router-dom";

export default function CTA() {
  return (
    <>
      {/* Call to Action */}
      <div className="bg-blue py-12 text-center">
        <h2 className="font-bold text-white">
          Ready To Transform Your Compliance Journey ?
        </h2>
        <p className="text-white mt-4">Get started with EazyComply services today.</p>
        <Link
          to="/contact"
          className="mt-8 inline-block bg-white text-blue font-semibold py-3 px-6 rounded-lg shadow-md hover:bg-gray-100 transition duration-300"
        >
          Contact Us
        </Link>
      </div>
    </>
  );
}
