import React from "react";
import { motion } from "framer-motion";

const pageTransition = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const Transition = (WrappedComponent) => {
  return function WithPageTransition(props) {
    return (
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={pageTransition}
        transition={{ duration: 0.5}}
      >
        <WrappedComponent {...props} />
      </motion.div>
    );
  };
};

export default Transition;
