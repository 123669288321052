import React from "react";
import Transition from "./transition";

const Soon = () => {
  return (
    <div className=" px-4">
      <div className=" max-w-[1440px] h-[70vh] flex items-center w-full mx-auto">
        <h1 className="text-orange text-9xl font-extrabold">Coming Soon</h1>
      </div>
    </div>
  );
};

export default Transition(Soon);
