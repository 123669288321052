import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const footerLinks = [
    {
      title: "Company",
      links: [
        { name: "About Us", href: "/about" },
        { name: "Leadership", href: "/leadership" },
        { name: "FAQs", href: "/faqs" },
        { name: "Blog", href: "/blog" },
        { name: "Contact Us", href: "/contact" },
        { name: "Term & Conditions", href: "/terms" },
        { name: "Privacy Policy", href: "/privacy-policies" },
      ],
    },
    {
      title: "Services",
      links: [
        { name: "Legal update service", href: "/services/legal-updates" },
        {
          name: "Compliance Audit Service",
          href: "/services/compliance-audit",
        },
        { name: "SCOPE Service", href: "/services/scope" },
        { name: "Risk Assessment Service", href: "/services/risk-assessment" },
        {
          name: "Control Effectiveness Service",
          href: "/services/control-effectiveness",
        },
        { name: "Pricing", href: "/pricing" },
      ],
    },
    {
      title: "Stage based Expertise",
      links: [
        { name: "GRC Compliance Audit", href: "/stage/grc-compliance-audit" },
        { name: "Start-Up Audit", href: "/stage/start-up-audit" },
        { name: "Due-Diligence Audit", href: "/stage/due-diligence-audit" },
        { name: "IPO Audit", href: "/stage/ipo-audit" },
        { name: "M&A Audit", href: "/stage/m-and-a-audit" },
        { name: "Off-Boarding Audit", href: "/stage/off-boarding-audit" },
        { name: "FDI Audit", href: "/stage/fdi-india-audit" },
      ],
    },
    {
      title: "Domain based Expertise",
      links: [
        {
          name: "Enterprise Risk Management",
          href: "/industries/enterprise-risk-management",
        },
        {
          name: "Environmental, Social, and Governance",
          href: "/industries/environmental-social-governance",
        },
        {
          name: "Digital Personal Data Protection",
          href: "/industries/data-privacy-protection",
        },
        {
          name: "Governance, Risk, and Compliance",
          href: "/industries/governance-risk-compliance",
        },
        {
          name: "Work Health and Safety",
          href: "/industries/workplace-health-safety",
        },
        {
          name: "Security and Loss Prevention",
          href: "/industries/security-loss-prevention",
        },
      ],
    },
  ];

  return (
    <footer className="bg-blue text-gray-300">
      <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className=" sm:columns-2 xl:columns-5 lg:columns-3 gap-6 *:break-inside-avoid *:mb-12">
          <div className="">
            <Link to="/" className="text-2xl font-bold text-white">
              <p className="flex items-center w-fit gap-2 border border-gray-500 px-4 py-2 rounded-xl">
                <img
                  src="/assets/logo/fav.png"
                  alt=""
                  className="h-[24px] aspect-square"
                />
                <span>EazyComply</span>
              </p>
            </Link>
            <p className="mt-4 text-sm">
              Revolutionizing compliance management with AI-powered solutions.
              Stay ahead of regulatory changes and streamline your processes.
            </p>
          </div>
          {footerLinks.map((column, index) => (
            <div key={index} className="col-span-1">
              <h3 className="text-lg font-bold text-orange mb-4">
                {column.title}
              </h3>
              <ul className="space-y-2">
                {column.links.map((link, linkIndex) => (
                  <li key={linkIndex}>
                    <Link
                      to={link.href}
                      className="text-sm hover:text-white transition-colors duration-200 capitalize"
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="mt-12 pt-8 border-t border-gray-700 text-center">
          <p className="text-sm">
            &copy; {new Date().getFullYear()} EazyComply. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
