import React from "react";
import { Link } from "react-router-dom";
import { Call, Mail } from "./svg";

const ConnectButton = ({ icon: Icon, href }) => (
  <Link
    to={href}
    className="duration-100 transform hover:scale-110 hover:bg-orange-600 flex items-center justify-center rounded-full aspect-square p-2.5 bg-white/30 shadow-lg shadow-dark/50"
  >
    <Icon size={32} color="#1c1c1c" />
  </Link>
);

export default function Connect() {
  return (
    <div className="fixed z-20 right-2 top-[80%] -translate-y-[50%] hidden lg:flex flex-col gap-4">
      <ConnectButton icon={Mail} href="mailto:info@eazycomply.in" />
      <ConnectButton icon={Call} href="mailto:info@eazycomply.in" />
    </div>
  );
}