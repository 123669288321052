import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <div className="px-4">
      <div className=" max-w-[1440px] mx-auto w-full flex lg:flex-row flex-col gap-4 *:w-full">
        <div className=" flex flex-col justify-center">
          <h1 className=" font-bold mb-4">
            Strengthening <span className=" text-orange">Businesses</span> through <span className=" text-orange">Compliance</span> Excellence
          </h1>
          <p>
            Focus on what truly matters—keeping your business secure and scaling
            new heights. After all, when compliance is effortless, success is
            inevitable!
          </p>
          <Link to={"/"}>
            <p className=" w-fit mt-8 -ml-2 px-4 py-2 bg-orange rounded-full text-white font-medium">
              Get Started
            </p>
          </Link>
        </div>

        <div>
          <img
            src="/assets/photos/main.png"
            alt=""
            className=" h-[400px] w-full object-cover rounded-xl"
          />
        </div>
      </div>

      <div className=" h-[100px] "></div>

      {/* <div className=" mt-20 max-w-[1440px] w-full mx-auto">
        <h2 className=" text-center font-bold"> Trusted by : </h2>
        <div className=" mt-8 flex flex-wrap gap-8 justify-center content-center">
          {[...Array(5)].map((_, i) => {
            return (
              <div key={i}>
                <img
                  src="https://hyperproof.io/wp-content/uploads/2022/12/logo-color-fortinet.png"
                  alt=""
                  className=" w-[200px] "
                />
              </div>
            );
          })}
        </div>
      </div> */}
    </div>
  );
}
