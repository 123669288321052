import React from "react";
import Transition from "../../comps/transition";
import { Link } from "react-router-dom";
import CTA from "../../comps/CTA";

const Privacy = () => {
  return (
    <div className="">
      <div
        className=" md:px-8 px-4 py-12 md:min-h-[600px] min-h-[500px] flex items-end justify-center relative z-0"
        style={{
          borderImage:
            "fill 1 linear-gradient(90deg, rgba(13,34,71,1) 0%, rgba(203,254,255,0.2) 100%)",
        }}
      >
        <img
          src="/assets/photos/terms.svg"
          alt=""
          className=" absolute inset-0 w-full h-full -z-10 object-cover"
        />
        <div className=" mt-32 flex flex-col max-w-[1440px] mx-auto justify-end w-full text-dark *:text-center">
          <h2 className=" font-bold">Data Privacy and Security Policy</h2>

          <h4 className="mt-4">
            <span className=" font-bold">Latest Update on:</span> 16th August
            2024
          </h4>
        </div>
      </div>

      {/* Intro */}
      <BlDiv>
        <h3 className="font-bold"> Introduction </h3>
        <p className=" text-lg">
          <span className=" font-bold text-orange">
            HAWK EYE RISK MANAGEMENT PRIVATE LIMITED
          </span>{" "}
          running a SAAS Platform named as{" "}
          <span className=" font-bold text-orange"> EAZYCOMPLY</span> considers
          User (as defined below) relationship and data security to be an
          important component of their service offerings through Our Website (as
          defined below). We are committed to maintain the confidentiality,
          integrity and security of any Personal Information (as defined below)
          of Our Users. We are proud of Our privacy practices and the strength
          of Our Website security and want You to know how We protect Your
          information and use it to provide You with Our products and services.
          This Data Privacy and Security Policy (“Policy”) enables Us to guard
          against identity theft and provide security for User profiles and
          transactional history. We shall constantly re-evaluate this Policy and
          adapt it to meet data security standards and to deal with new
          challenges. This Policy informs You of Our modalities regarding the
          collection, use, and disclosure of Personal Information (as defined
          below) when You use Website and the choices You have associated with
          Your data.
        </p>

        <p>
          We use Your Personal Information to provide and improve the quality of
          Our services provided through Website. By using the Website, You agree
          to the collection and use of Personal Information in accordance with
          this Policy. Unless otherwise defined in this Policy, terms used in
          this Privacy Policy have the same meanings as in Our{" "}
          <Link to={"/terms"}>
            {" "}
            <span className=" underline italic font-medium text-orange">
              {" "}
              Terms and Conditions
            </span>{" "}
          </Link>{" "}
          . Accordingly, this Policy shall be read in conjunction with the Terms
          and Conditions agreed by You while accessing Website.
        </p>
      </BlDiv>
      {/* Intro */}

      {/* Definition */}
      <OrDiv>
        <h2 className=" font-bold">Definitions</h2>
        <p>
          <span className=" font-bold text-white mr-[0.5ch]">
            {" "}
            “Applicable Law”
          </span>{" "}
          includes all applicable Indian statutes, enactments, acts of the state
          legislature or parliament, laws, ordinances, rules, bye-laws,
          regulations, notifications, guidelines, directions, directives and
          orders of any governmental authority, statutory authority, board, as
          may be applicable and in each case, any implementing regulation or
          interpretation issued thereunder including any successor Applicable
          Law;
        </p>
        <p>
          <span className=" font-bold text-white mr-[0.5ch]">“Dependant”</span>{" "}
          shall have the meaning as ascribed to it in paragraph 12.
        </p>
        <p>
          <span className=" font-bold text-white mr-[0.5ch]"> “Person”</span>
          shall mean any individual (including personal representatives,
          executors or heirs of a deceased individual) or legal entity,
          including but not limited to, any partnership, joint venture,
          corporation, trust, unincorporated organisation, limited liability
          company, limited liability partnership or governmental authority
        </p>

        <p>
          <span className=" font-bold text-white mr-[0.5ch]">
            “Personal Information”
          </span>
          shall mean any personally identifiable information provided to Us by
          User while accessing Website and shall include information in relation
          to cookies, User Information and Usage Data
        </p>

        <p>
          <span className=" font-bold text-white mr-[0.5ch]">
            {" "}
            “Service Providers”
          </span>
          shall have the meaning as ascribed to it in paragraph 9
        </p>

        <p>
          <span className=" font-bold text-white mr-[0.5ch]">
            “User” / “You” / “Your”
          </span>
          shall mean any natural or legal person who has access to and is using
          Website
        </p>

        <p>
          <span className=" font-bold text-white mr-[0.5ch]">
            {" "}
            “Usage Data”
          </span>
          shall have the meaning as ascribed to it in paragraph 3.1
        </p>

        <p>
          <span className=" font-bold text-white mr-[0.5ch]">
            {" "}
            “User Information”
          </span>
          shall have the meaning as ascribed to it in paragraph 3.3
        </p>

        <p>
          <span className=" font-bold text-white mr-[0.5ch]"> “Website”</span>
          shall mean shall mean and include and application or software or
          mobile application of WE, any successor website/ applications, any
          website of related entity or any other channel facilitated and
          permitted by WE including but not limited to App, any other digital
          medium including phone, displays, emails, social media interfaces,
          messaging interfaces, wallet, payment intermediaries using WE’s
          interface.
        </p>
      </OrDiv>
      {/* Definition */}

      {/* Information Collection, Storage and Use */}
      <BlDiv>
        <h2 className=" font-bold">Information Collection, Storage and Use</h2>
        <p>
          We collect Your Personal Information when You successfully submit
          information while using Our Website (“User Information”). User
          Information is the data that can be used to uniquely identify or
          contact a person and/or the business entity that a person represents.
        </p>
        <p>
          Without prejudice to the generality of paragraph above, We may request
          such additional User Information as may be required for accessing and
          availing any services through Websites as may be specified in Terms
          and Conditions.
        </p>
        <p>
          We may also collect information that Your browser sends whenever You
          access the Website (“Usage Data”). This Usage Data may include
          information such as Your computer's internet protocol address, browser
          type, browser version, the pages of Website that You visit, the time
          and date of Your visit, the time spent on those pages, unique device
          identifiers and other diagnostic data.
        </p>

        <p>
          When You access Website by or through a mobile device, this Usage Data
          may include information such as the type of mobile device You use,
          Your mobile device unique ID, the IP address of Your mobile device,
          Your mobile operating system, the type of mobile Internet browser You
          use, unique device identifiers and other diagnostic data.
        </p>

        <p>
          By using Website, You authorize Us to collect, store, process, handle
          and use User Information and Usage Data, in accordance with this
          Policy and any other terms and conditions of use of Website (as
          amended from time to time).
        </p>

        <p>
          Personal Information provided by You is used by Us to improve
          Websites/Application. We do not share Your Personal Information with
          any third parties for commercial use or revenue generation.
        </p>

        <p>
          We may share Personal Information such as Your name, mobile number and
          email address with third-party service providers appointed by Us for
          sending SMS / Email communications to You in relation to Website and
          Our products and services. We ensure that such third-party service
          providers maintain strict confidentiality of Your Personal
          Information.
        </p>
      </BlDiv>
      {/* Information Collection, Storage and Use */}

      {/* Tracking & Cookies Data */}
      <OrDiv>
        <h2 className=" font-bold">Tracking & Cookies Data</h2>
        <p>
          We use cookies and similar tracking technologies to track the activity
          on Our Website and hold certain information.
        </p>
        <p>
          Cookies are files with a small amount of data which may include an
          anonymous unique identifier. Cookies are sent to Your browser from a
          website and stored on Your device. Tracking technologies also used are
          beacons, tags, and scripts to collect and track information and to
          improve and analyse Our Website.
        </p>
        <p>
          You can instruct Your browser to refuse all cookies or to indicate
          when a cookie is being sent. However, if You do not accept cookies,
          You may not be able to use some portions of Our Website.
        </p>

        <p>
          Examples of Cookies We use:
          <br />
          <ul className="mt-4 max-w-[1440px] w-[95%] mx-auto list-disc list-outside *:mb-2">
            <li>
              <p>
                <span className=" font-bold text-white mr-[0.75ch]">
                  Session Cookies.
                </span>
                We use Session Cookies to operate Our Website.
              </p>
            </li>
            <li>
              <p>
                <span className=" font-bold text-white mr-[0.75ch]">
                  Preference Cookies.
                </span>
                We use Preference Cookies to remember Your preferences and
                various settings.
              </p>
            </li>
            <li>
              <p>
                <span className=" font-bold text-white mr-[0.75ch]">
                  Security Cookies.
                </span>
                Security Cookies. We use Security Cookies for security
                purposes.ite.
              </p>
            </li>
          </ul>
        </p>
      </OrDiv>
      {/* Tracking & Cookies Data */}

      {/* Data Usage  */}
      <BlDiv>
        <h2 className="font-bold"> Use of Data</h2>

        <ul className=" mx-auto max-w-[1440px] w-[95%] list-disc list-outside *:mb-2">
          <li>
            <p>
              In addition to the usage of Personal Information as specifically
              mentioned above in paragraph 3 of this Policy, We use the
              collected Personal Information and Tracking & Cookies Data.
            </p>
          </li>
          <li>
            <p>
              To provide and maintain Our services; • To allow You to
              participate in interactive features of Our Website when You choose
              to do so
            </p>
          </li>
          <li>
            <p>To provide customer care and support</p>
          </li>
          <li>
            <p>
              To provide analysis or valuable information so that We can improve
              Website
            </p>
          </li>
          <li>
            <p>To monitor the usage of Website and/or</p>
          </li>
          <li>
            <p>To detect, prevent and address technical issues.</p>
          </li>
        </ul>
      </BlDiv>
      {/* Data Usage  */}

      {/* Transfer of Personal Information  */}
      <OrDiv>
        <h2 className=" font-bold">Transfer of Personal Information </h2>
        <p>
          Your Personal Information may be transferred and maintained on the
          devices located outside of Your state, province, country or other
          governmental jurisdiction where the data protection laws may differ
          from those from Your jurisdiction.
        </p>
        <p>
          If You are located outside India and choose to provide information to
          Us, please note that We transfer the Personal Information to India and
          process it there.
        </p>
        <p>
          Your consent to this Policy followed by Your submission of such
          information represents Your agreement to such transfer.
        </p>
        <p>
          We will take all steps reasonably necessary to ensure that Your
          Personal Information is treated securely and in accordance with this
          Policy and no transfer of Your Personal Information will take place to
          an organization or a country unless there are adequate controls in
          place including the security of Your Personal Information.
        </p>
      </OrDiv>
      {/* Transfer of Personal Information  */}

      {/* Disclosure of Personal Information */}
      <BlDiv>
        <h2 className="font-bold">Disclosure of Personal Information</h2>
        <p>
          Notwithstanding anything mentioned in this Policy, We reserve the
          right to utilize, share and/or disclose Your Personal Information in
          the good faith belief that such action is necessary.
        </p>

        <ul className=" max-w-[1440px] w-[95%] mx-auto list-disc list-outside *:mb-2">
          <li>
            <p>
              To comply with orders of governmental authorities that have
              jurisdiction over it or as otherwise required by Applicable Law
            </p>
          </li>
          <li>
            <p>To protect and defend the rights or property of We</p>
          </li>
          <li>
            <p>
              To prevent or investigate possible wrongdoing in connection with
              Website
            </p>
          </li>
          <li>
            <p>
              To protect the personal safety of Users of Website and/or people
              in general
            </p>
          </li>
          <li>
            <p>To protect Us against any liability under Applicable Law</p>
          </li>
          <li>
            <p>
              In case of determination, in Our sole discretion, that disclosure
              of Personal Information is necessary to identify, contact, or
              bring legal action against You.
            </p>
          </li>
        </ul>

        <p>
          Notwithstanding the above, We reserve the right to retain such
          Personal Information that forms part of anonymized and aggregated data
          derived from Personal Information which may be used for improvement of
          Our Website, to produce analytical reports, marketing, advertising or
          such other activities as We may deem fit.
        </p>
      </BlDiv>
      {/* Disclosure of Personal Information */}

      {/* Security of Data */}
      <OrDiv>
        <h2 className="font-bold">Security of Data</h2>
        <p>
          To prevent any form of unlawful interception or misuse of User
          Information, We use reasonable physical, electronic and managerial
          procedures to safeguard and secure Personal Information collected. We
          use reasonable secure and technologically appropriate measures, in
          compliance with the Information Technology Act, 2000 and the rules
          related thereto to protect You against loss or misuse of Your Personal
          Information including internal reviews of data collection, storage and
          processing practices and other reasonable security measures which are
          equivalent to security measures that We use to protect Our own
          confidential information. However, as You are aware, no method of
          transmission over the Internet or method of electronic storage is
          completely free of security risks and We do not make any
          representation in respect of the same. While we strive to use
          commercially acceptable means to protect your Personal Information, we
          cannot guarantee its absolute security.
        </p>
      </OrDiv>
      {/* Security of Data */}

      {/*  Service Providers */}
      <BlDiv>
        <h2 className="font-bold">Service Providers</h2>
        <p>
          We may employ third party companies and individuals
          <span className=" text-orange font-bold"> (“Service Providers”)</span>
        </p>
        <ul className=" mx-auto max-w-[1440px] w-[95%] list-disc list-outside *:mb-2">
          <li>
            <p> To facilitate Our services and/or Website </p>
          </li>
          <li>
            <p> To provide Our services and/or Website on Our behalf </p>
          </li>
          <li>
            <p> To perform Our service-related services </p>
          </li>
          <li>
            <p>
              To assist Us in analysing the quality standards and various
              modalities involved in relation to Our services and/or Website.
            </p>
          </li>
        </ul>

        <p>
          These Service Providers may have access to Your Personal Information
          only to perform these tasks on Our behalf and are obligated not to
          disclose or use it for any other purpose.
        </p>
      </BlDiv>
      {/*  Service Providers */}

      {/*  Analytics */}
      <OrDiv>
        <h2 className=" font-bold"> Analytics</h2>
        <p>
          We may use third-party Service Providers to monitor and analyze the
          use of Our Website/Application.
        </p>

        <p>
          Firebase Analytics by Google <br /> Firebase Analytics service is
          provided by Google! Inc.
        </p>
        <p>
          For more information on the privacy practices and policies of Google!,
          please visit their Privacy Policy pages at
        </p>

        <Link to={"https://firebase.google.com/policies/analytics "}>
          <p className=" underline text-white ">
            https://firebase.google.com/policies/analytics{" "}
          </p>
        </Link>
      </OrDiv>
      {/*  Analytics */}

      {/* Third-Party Links  */}
      <BlDiv>
        <h2 className="font-bold">Third-Party Links</h2>

        <p>
          Our Website may contain links to other sites that are not operated by
          Us. If You click on a third-party link, You may be directed to that
          third-party's site. We strongly advise You to review the privacy
          policy of every such third-party site You visit.
        </p>
        <p>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third-party sites or services.
        </p>
      </BlDiv>
      {/* Third-Party Links  */}

      {/* Children's Privacy */}
      <OrDiv>
        <h2 className="font-bold">Children's Privacy</h2>

        <p>
          Our services and/or Website do not address anyone who are incompetent
          to contract within the meaning of the Indian Contract Act, 1872
          including but not limited to minors, un-discharged insolvents etc.{" "}
          <span className=" italic text-white">(“Dependent”).</span>
        </p>

        <p>
          We do not knowingly collect personally identifiable information from
          anyone who is incompetent to contract. If You are a parent or a
          guardian and You are aware that Your Dependent has provided Us with
          his/her Personal Information, please contact Us forthwith. If We
          become aware that We have collected Personal Information from any
          Dependant without verification of parental/guardian consent, We take
          requisite steps to remove such Personal Information from Our servers.
        </p>
      </OrDiv>
      {/* Children's Privacy */}

      {/* Indemnification */}
      <BlDiv>
        <h2 className="font-bold">Indemnification</h2>
        <p>
          You agree to indemnify, defend and hold harmless Us and Our parent,
          subsidiaries, affiliates, partners, officers, directors, agents,
          contractors, licensors, service providers, subcontractors, suppliers,
          interns and employees, harmless from any claim or demand, including
          reasonable attorneys’ fees, made by any third-party due to or arising
          out of Your breach of this Policy or the documents they incorporate by
          reference (including Terms and Conditions) or Your violation of any
          law or the rights of a third-party.
        </p>
      </BlDiv>
      {/* Indemnification */}

      {/* Changes to this Policy */}
      <OrDiv>
        <h2 className="font-bold">Changes to this Policy</h2>
        <p>
          We reserve the right to update, modify and amend any of the terms of
          this Policy from time to time, without prior intimation to You. We
          will post these changes on the Website/Application for Your
          information. We shall not be liable for any failure or negligence on
          Your part to review the updated Policy before accessing the
          Website/Application. Your continued access to the Website, following
          changes to this Policy, will constitute Your acceptance of those
          changes.
        </p>
        <p>
          You are advised to review this Policy periodically for any changes.
          Changes to this Policy are effective as and when they are posted on
          this page.
        </p>
      </OrDiv>
      {/* Changes to this Policy */}

      {/* Contact Us */}
      <BlDiv>
        <h2 className="font-bold">Contact Us</h2>
        <p>
          If You have any questions about this ,
          <br />
          please contact Us at{" "}
          <Link
            to={"mailto:office@eazycomply.in"}
            className=" underline italic"
          >
            office@eazycomply.in.
          </Link>
        </p>
      </BlDiv>
      {/* Contact Us */}

      {/* Email  */}
      <OrDiv>
        <h2 className="font-bold">Email Opt-Out</h2>
        <p>
          You can opt out of receiving Our marketing and update emails. To stop
          receiving Our promotional emails, please email{" "}
          <Link
            to={"mailto:stop@eazycomply.in"}
            className=" underline text-white italic font-bold"
          >
            stop@eazycomply.in
          </Link>{" "}
          . It may take about ten days to process Your request. Even if You opt
          out of getting marketing messages, We will still be sending You
          transactional messages through email and SMS in relation to Your
          usage/access of Website/Application.
        </p>
      </OrDiv>
      {/*  */}

      <CTA />
    </div>
  );
};

export default Transition(Privacy);

const OrDiv = ({ children, className }) => {
  return (
    <div className={`md:px-8 px-4 py-12 bg-orange text-[#111] ${className}`}>
      <div className="flex flex-col gap-6 mx-auto w-full max-w-[1440px]">
        {children}
      </div>
    </div>
  );
};

const BlDiv = ({ children, className }) => {
  return (
    <div className={`md:px-8 px-4 py-12 bg-blue text-[#fff] ${className}`}>
      <div className="flex flex-col gap-6 mx-auto w-full max-w-[1440px]">
        {children}
      </div>
    </div>
  );
};
