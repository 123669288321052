import React from "react";
import { Link, useParams } from "react-router-dom";
import { ServicePageData } from "../../../comps/data";
import Transition from "../../../comps/transition";
import CTA from "../../../comps/CTA";
import { ToLink } from "../../../comps/svg";

const ServiceDetail = () => {
  const { id } = useParams();
  const service = ServicePageData.find((service) => service.id === id);

  if (!service) {
    return <div className="text-center text-xl mt-10">Service not found!</div>;
  }

  return (
    <div className="min-h-screen">
      {/* Hero Section */}

      <div
        className=" md:px-8 px-4 py-8 relative min-h-[500px] flex flex-col justify-end gap-4"
        style={{
          borderImage:
            "fill 1 linear-gradient(90deg, rgba(13,34,71,0.5) 0%, rgba(203,254,255,0.2) 100%)",
        }}
      >
        <div className=" absolute inset-0 h-full w-full -z-10">
          <img
            src="/assets/servicePage/services.avif"
            alt=""
            className=" h-full w-full object-cover"
          />
        </div>

        <div className="text-white max-w-[1440px] w-full mx-auto">
          <h1 className=" font-bold mb-4 -ml-0.5 max-w-[768px]">
            {" "}
            {service.name}{" "}
          </h1>
          <p className=" text-lg max-w-[768px] font-medium">{service.description}</p>
        </div>
      </div>

      {/* Service Description */}
      <div className="md:px-8 px-4 py-20 bg-blue text-white ">
        <div className="max-w-[1440px] w-full mx-auto flex lg:flex-row flex-col justify-between gap-4">
          <div className=" w-full max-w-[900px] flex flex-col gap-4">
            <h2 className=" font-bold">What is {service.short_name} ?</h2>
            <p className=" text-lg ">{service.answer}</p>

            <img
              src={service.img}
              alt=""
              className=" max-h-[500px] object-cover mt-4"
            />
          </div>

          <div className=" rounded-xl lg:w-[25%] h-fit bg-white p-8 text-dark ">
            <h2 className="font-bold mb-8"> Other Services </h2>

            <div>
              {ServicePageData.map((s, i) => {
                return (
                  <Link
                    to={`/services/${s.id}`}
                    key={i}
                    className={` ${s.id === service.id ? " hidden " : ""} `}
                  >
                    <p className=" mb-4 font-medium text-[17px] hover:underline duration-100 capitalize flex items-start gap-2">
                      <span>
                        <ToLink color={"#1f1f1f"} size={24} />
                      </span>
                      <span> {s.short_name}</span>
                    </p>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* Benefits */}
      <div className="md:px-8 px-4 py-20 bg-orange text-[#111]">
        <div className="max-w-[1440px] mx-auto w-full flex lg:flex-row flex-col gap-8 *:w-full">
          <div>
            <h1 className=" font-bold mb-4 -ml-1 "> How it Helps </h1>
            <p> {service.how} </p>
          </div>

          <div>
            <h1 className=" font-bold mb-4 -ml-1 "> Benefits </h1>
            <div className=" mx-auto max-w-[1440px] w-[95%] ">
              <ul className=" list-disc list-outside">
                {service.benefits.map((benefit, i) => {
                  return (
                    <li key={i} className=" text-lg font-medium">
                      {benefit}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Benefits */}

      <CTA />
    </div>
  );
};

export default Transition(ServiceDetail);
